import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faUsers, faCalendarAlt, faArrowRight, faBookOpen } from '@fortawesome/free-solid-svg-icons';

const Restaurant = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="container">
      <article className="message" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faBookOpen} />&nbsp;&nbsp;Restaurants
          </p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
      </article>

      <div className="columns">
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faUtensils} /> Restaurants
              </p>
              <p>Find the perfect restaurant for your culinary adventure.</p>
              <br />
              <a href="/mealtime" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>

        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faUsers} /> Group Bookings
              </p>
              <p>Plan and book your group dining experiences with ease.</p>
              <br />
              <a href="/group-bookings" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>

        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faCalendarAlt} /> Calendar
              </p>
              <p>Stay organized and plan your dining adventures with our calendar.</p>
              <br />
              <a href="/calendar" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Restaurant;