import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookBookmark } from '@fortawesome/free-solid-svg-icons';
import './RestaurantBookmark.css';

const RestaurantBookmark = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecommendations = async () => {
      const db = getFirestore();
      const recommendationsCollection = collection(db, 'meal-recommendations');
      const recommendationsSnapshot = await getDocs(recommendationsCollection);
      const recommendationsData = recommendationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecommendations(recommendationsData);
    };
    fetchRecommendations();
  }, []);

  const handleRecommendationClick = (recommendation) => {
    setSelectedRecommendation(recommendation);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedRecommendation(null);
    setShowModal(false);
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faBookBookmark} /> Restaurant Bookmarks
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="recommendation-list">
            {recommendations.map((recommendation) => (
              <div key={recommendation.id} className="recommendation-item" onClick={() => handleRecommendationClick(recommendation)}>
                <h3>{recommendation.Cuisine}</h3>
                <p>{recommendation.Food}</p>
              </div>
            ))}
          </div>
        </div>
      </article>
      {showModal && selectedRecommendation && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content modal-content-custom">
            <div className="box">
              <div className="modal-header">
                <h2>{selectedRecommendation.Cuisine}</h2>
                <button className="close-button" onClick={closeModal}>
                  <FaTimes />
                </button>
              </div>
              <div dangerouslySetInnerHTML={{ __html: selectedRecommendation.Recommendation.replace(/\n/g, '<br/>') }} />
              <div className="modal-buttons">
                <button className="button" onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
      )}
    </div>
  );
};

export default RestaurantBookmark;