import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import './SnackBook.css';

const SnackBook = () => {
  const [snacks, setSnacks] = useState([]);
  const [selectedSnack, setSelectedSnack] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSnacks = async () => {
      const db = getFirestore();
      const snacksCollection = collection(db, 'snacks');
      const snacksSnapshot = await getDocs(snacksCollection);
      const snacksData = snacksSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSnacks(snacksData);
    };
    fetchSnacks();
  }, []);

  const handleSnackClick = (snack) => {
    setSelectedSnack(snack);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedSnack(null);
    setShowModal(false);
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faBook} /> Snack Book
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="snack-cards">
            {snacks.map((snack) => (
              <div key={snack.id} className="snack-card" onClick={() => handleSnackClick(snack)}>
                <img src={`data:image/png;base64,${snack.imageData}`} alt="Snack" className="snack-image" />
              </div>
            ))}
          </div>
        </div>
      </article>
      {showModal && selectedSnack && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content modal-content-custom">
            <div className="box">
              <div className="modal-header">
                <h2>{selectedSnack.recommendation.split('\n')[0].replace('###', '').trim()}</h2>
                <button className="close-button" onClick={closeModal}>
                  <FaTimes />
                </button>
              </div>
              <div className="snack-details">
                <div dangerouslySetInnerHTML={{ __html: selectedSnack.recommendation.replace(/\n/g, '<br/>') }} />
              </div>
              <div className="modal-buttons">
                <button className="button" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
      )}
    </div>
  );
};

export default SnackBook;