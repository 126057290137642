import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDown, faSortAmountUp, faMinus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import 'bulma/css/bulma.css';
import OpenAI from 'openai';
import './GroceryListTotalPrice.css';

const groceryStores = ['Tesco', 'Lidl', 'M&S', 'Waitrose', 'Co-op', 'Aldi', 'Sainsbury\'s'];

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true,
});

const GroceryListTotalPricePage = () => {
  const location = useLocation();
  const { checkedIngredientsPrices, totalCost, wastePercentage, recipe } = location.state || {};
  const [sortOrder, setSortOrder] = useState('asc');
  const [preferredStore, setPreferredStore] = useState('');
  const [preferredLocation, setPreferredLocation] = useState('');
  const [servings, setServings] = useState(1);
  const [ingredients, setIngredients] = useState(checkedIngredientsPrices || []);
  const [totalPrice, setTotalPrice] = useState(totalCost || 0);
  const [summary, setSummary] = useState('');
  const [summaryContent, setSummaryContent] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSortChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleUpdateClick = async () => {
    try {
      setLoading(true); // Set loading state to true when button is clicked

      const requestData = {
        model: 'ft:gpt-3.5-turbo-1106:agriweiss:saladhr-uk-prices:9Ei4nk98',
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant at SaladHR you help provide the best grocery price advice in London, United Kingdom.',
          },
          {
            role: 'user',
            content: `Based on any of the following {${preferredLocation}} {${servings} servings} {price} {${ingredients
              .map((ingredient) => ingredient.productName)
              .join(', ')}} provide a clear grocery list with price per item and total price, if possible include brands`,
          },
        ],
        temperature: 1,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      };

      console.log('Request data sent to OpenAI API:', requestData);

      const response = await openai.chat.completions.create(requestData, {
        baseURL: 'https://api.openai.com/v1',
      });

      console.log('Response received from OpenAI API:', response);

      if (response.data && response.data.choices && response.data.choices.length > 0) {
        const content = response.data.choices[0].message.content;
        console.log('Response content:', content);
        setSummaryContent(content); // Storing content data
        setSummary(content); // Update summary state
      } else {
        console.error('Unexpected response from OpenAI API:', response.data);
        setSummary('Failed to fetch grocery prices (unexpected response)');
      }
    } catch (error) {
      console.error('Error fetching grocery prices:', error);
      setSummary('Failed to fetch grocery prices');
    } finally {
      setLoading(false); // Set loading state to false after request is completed
    }
  };

  const handleDeleteItem = (index) => {
    const newIngredients = [...ingredients];
    const deletedItem = newIngredients.splice(index, 1)[0];
    setIngredients(newIngredients);
    setTotalPrice(totalPrice - parseFloat(deletedItem.price.replace('£', '')));
  };

  const sortedIngredients = ingredients.sort((a, b) => {
    const priceA = parseFloat(a.price.replace('£', ''));
    const priceB = parseFloat(b.price.replace('£', ''));
    return sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
  });

  return (
    <div className="grocery-list-total-price-container">
      <div className="box">
        <div className="recipe-info">
          <div className="image-container has-text-centered">
            {recipe && <img src={`data:image/png;base64,${recipe.imageData}`} alt="Recipe" className="recipe-image" />}
          </div>
          <h1 className="title is-size-4-mobile">{recipe ? recipe.recommendation.split('\n')[0].replace('###', '').trim() : 'Recipe Information'}</h1>
          <p className="has-text-centered is-size-6-mobile">Please adjust the information filter for more accurate prices</p>
          <div className="filter">
            <div className="field">
              <label className="label is-size-6-mobile">Preferred Store</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={preferredStore} onChange={(e) => setPreferredStore(e.target.value)}>
                    <option value="">Select a store</option>
                    {groceryStores.map((store) => (
                      <option key={store} value={store}>
                        {store}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label is-size-6-mobile">Preferred Location</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Enter location"
                  value={preferredLocation}
                  onChange={(e) => setPreferredLocation(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label is-size-6-mobile">Servings</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  min="1"
                  value={servings}
                  onChange={(e) => setServings(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button className={`button is-primary is-fullwidth ${loading ? 'is-loading' : ''}`} onClick={handleUpdateClick}>
                  {loading ? 'Loading...' : 'Update'}
                </button>
              </div>
            </div>
          </div>
          {summary && (
            <div className="summary">
              <h3 className="title is-3 is-size-5-mobile">Summary</h3>
              <p className="is-size-6-mobile">{summary}</p>
            </div>
          )}
        </div>
        {summaryContent && (
          <div className="summary-content">
            <h3 className="title is-3 is-size-5-mobile">Summary Content</h3>
            <p className="is-size-6-mobile">{summaryContent}</p>
          </div>
        )}
        <h1 className="title is-size-4-mobile">Total Cost: £{totalPrice.toFixed(2)}</h1>
        <h2 className="subtitle is-size-5-mobile">Ingredients:</h2>
        <div className="table-container">
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <th className="is-size-6-mobile">Product Name</th>
                <th className="is-size-6-mobile">
                  Brand
                  <FontAwesomeIcon
                    icon={sortOrder === 'asc' ? faSortAmountDown : faSortAmountUp}
                    onClick={handleSortChange}
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                  />
                </th>
                <th className="is-size-6-mobile">Price</th>
                <th className="is-size-6-mobile">Price Per Mass</th>
                <th className="is-size-6-mobile"></th>
              </tr>
            </thead>
            <tbody>
              {sortedIngredients.map((ingredient, index) => (
                <tr key={index}>
                  <td className="is-size-6-mobile">{ingredient.productName || '-'}</td>
                  <td className="is-size-6-mobile">{ingredient.brand || '-'}</td>
                  <td className="is-size-6-mobile">{ingredient.price || '-'}</td>
                  <td className="is-size-6-mobile">{ingredient.pricePerMass || '-'}</td>
                  <td className="is-size-6-mobile">
                    <button className="button is-small is-danger" onClick={() => handleDeleteItem(index)}>
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={faMinus} />
                      </span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h2 className="subtitle is-size-5-mobile">Waste Percentage: {wastePercentage ? wastePercentage.toFixed(2) : 'N/A'}%</h2>
      </div>
    </div>
  );
};

export default GroceryListTotalPricePage;