import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CommunityRecipeSwap.css';

const initialRecipes = [
  {
    id: 1,
    title: 'Hearty Vegetable Soup',
    author: 'Alice',
    description: 'A nourishing and easy to make vegetable soup, perfect for all seasons.',
    comments: ['Looks delicious!', 'Amazing']
  },
  {
    id: 2,
    title: 'Classic Margherita Pizza',
    author: 'Bob',
    description: 'Homemade pizza with fresh tomatoes, mozzarella, and basil.',
    comments: ['This is my family favorite.', 'Thank you for sharing!']
  },
];

const CommunityRecipeSwap = () => {
  const [recipes, setRecipes] = useState(initialRecipes);
  const [newComment, setNewComment] = useState('');
  const navigate = useNavigate();

  const handleAddComment = (recipeId) => {
    if (!newComment.trim()) return;

    const updatedRecipes = recipes.map(recipe => {
      if (recipe.id === recipeId) {
        return { ...recipe, comments: [...recipe.comments, newComment] };
      }
      return recipe;
    });

    setRecipes(updatedRecipes);
    setNewComment('');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <article className="message" style={{ color: '#698B01' }}>
        <div className="message-header">
          <p>Community Recipe Swap</p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
        <div className="message-body">
          {recipes.map(recipe => (
            <div key={recipe.id} className="box recipe-box">
              <h3 className="title is-4">{recipe.title}</h3>
              <p>Shared by: {recipe.author}</p>
              <p>{recipe.description}</p>
              <div>
                <strong>Comments:</strong>
                <ul>
                  {recipe.comments.map((comment, index) => (
                    <li key={index}>{comment}</li>
                  ))}
                </ul>
                <div className="field has-addons">
                  <div className="control is-expanded">
                    <input
                      className="input"
                      type="text"
                      placeholder="Add a comment..."
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && handleAddComment(recipe.id)}
                    />
                  </div>
                  <div className="control">
                    <button
                      className="button is-primary"
                      onClick={() => handleAddComment(recipe.id)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </article>
    </div>
  );
};

export default CommunityRecipeSwap;