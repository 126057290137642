import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookmark, faTrophy, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Dashboard = ({ user }) => {
  const userName = user ? user.name : '';

  return (
    <div>
      <div className="container">
        <section className="hero is-small" style={{ backgroundColor: 'white' }}>
          <div className="hero-body">
            <div className="container">
              <h1 className="title" style={{ color: 'black' }}>Welcome, {userName}!</h1>
              <h2 className="subtitle" style={{ color: 'black' }}>Explore and connect with our AI assistants</h2>
            </div>
          </div>
        </section>
        <br></br>
        <div className="columns">
          <div className="column is-one-third">
            <div className="card">
              <div className="card-content">
                <p className="title">
                  <FontAwesomeIcon icon={faBook} /> Sarah
                </p>
                <p>Create your meals, like never before.</p>
                <br />
                <Link to="/sarah" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                  Go&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="card">
              <div className="card-content">
                <p className="title">
                  <FontAwesomeIcon icon={faBookmark} /> Seth
                </p>
                <p>Your Ultimate Foodie Hub, Organized.</p>
                <br />
                <Link to="/seth" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                  Go&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="card">
              <div className="card-content">
                <p className="title">
                  <FontAwesomeIcon icon={faTrophy} /> Sydney
                </p>
                <p>Earn rewards for eating healthy</p>
                <br />
                <Link to="/sydney" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                  Go&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;