import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBowlFood, faWheatAwnCircleExclamation, faUtensils, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBKhJVJRPzIOXht2I40xNM7AsoC6W31Uuc',
  authDomain: 'saladhr-ffd61.firebaseapp.com',
  projectId: 'saladhr-ffd61',
  storageBucket: 'saladhr-ffd61.appspot.com',
  messagingSenderId: '1070443053637',
  appId: '1:1070443053637:web:28624b681b29acb95ed92c',
  measurementId: 'G-ESF78XF7SK',
};

// Initialize Firebase
initializeApp(firebaseConfig);
const db = getFirestore();

const MealTime = () => {
  const [food, setFood] = useState('');
  const [cuisine, setCuisine] = useState('');
  const [dietary, setDietary] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({ lat: 51.5072, lng: -0.1275 }); // Default location is London
  const [locationInput, setLocationInput] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const nestedRequestBody = JSON.stringify({
      body: JSON.stringify({
        Food: food,
        Cuisine: cuisine,
        Location: `${location.lat},${location.lng}`,
        Dietary: dietary,
      }),
    });

    try {
      const response = await fetch('https://csu97f7d27.execute-api.eu-west-1.amazonaws.com/prod/mealrecommendationv2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'tKeEiRwzq91d5N3rMsxRR7RZ3pUHIReq6Kl3lTW4',
        },
        body: nestedRequestBody,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const responseBody = JSON.parse(data.body);
      setRecommendation(responseBody.recommendation);
    } catch (error) {
      console.error('Error during fetch operation:', error.message);
      setRecommendation('Error fetching recommendation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to save recommendation data to Firestore
  const saveRecommendationToFirestore = async () => {
    try {
      await addDoc(collection(db, 'meal-recommendations'), {
        Food: food,
        Cuisine: cuisine,
        Location: `${location.lat},${location.lng}`,
        Dietary: dietary,
        Recommendation: recommendation,
      });
      console.log('Recommendation saved successfully');
      alert('Recommendation saved successfully');
    } catch (error) {
      console.error('Error saving recommendation to Firestore:', error);
      alert('Error saving recommendation.');
    }
  };

  const handleLocationInputChange = (e) => {
    setLocationInput(e.target.value);
  };

  const handleLocationSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${locationInput}&key=YOUR_GOOGLE_MAPS_API_KEY`);
      const data = await response.json();

      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        setLocation({ lat, lng });
      } else {
        console.error('Error fetching location:', data.status);
      }
    } catch (error) {
      console.error('Error fetching location:', error);
    }
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faUtensils} /> Restaurant Recommendation
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: '#FFFFFF' }}>
          <APIProvider apiKey="AIzaSyB-VZ6yiz0SaAvvvDdaQG0WjSC3f_Vl4sM">
            <Map center={location} zoom={12} style={{ height: '400px', width: '100%' }}>
              <Marker position={location} draggable onDragEnd={(event) => setLocation(event.lngLat)} />
            </Map>
          </APIProvider>
          <form onSubmit={handleLocationSubmit}>
            <div className="field">
              <label className="label">
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Location
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={locationInput}
                  onChange={handleLocationInputChange}
                  placeholder="Enter location"
                />
              </div>
              <div className="control">
                <button className="button" type="submit" style={{ backgroundColor: '#698B01', color: 'white' }}>
                  Update Location
                </button>
              </div>
            </div>
          </form>
          <form onSubmit={handleSubmit}>
            {/* Input Field for Food */}
            <div className="field">
              <label className="label">
                <FontAwesomeIcon icon={faBowlFood} /> Food
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={food}
                  onChange={(e) => setFood(e.target.value)}
                  placeholder="Enter food type"
                />
              </div>
            </div>

            {/* Input Field for Cuisine */}
            <div className="field">
              <label className="label">
                <FontAwesomeIcon icon={faFolderOpen} /> Cuisine
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={cuisine}
                  onChange={(e) => setCuisine(e.target.value)}
                  placeholder="Enter cuisine type"
                />
              </div>
            </div>

            {/* Input Field for Dietary Preferences */}
            <div className="field">
              <label className="label">
                <FontAwesomeIcon icon={faWheatAwnCircleExclamation} /> Dietary Preferences
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={dietary}
                  onChange={(e) => setDietary(e.target.value)}
                  placeholder="Enter dietary preferences"
                />
              </div>
            </div>

            {/* Submit Button */}
            <div className="control">
              <button className="button" type="submit" disabled={loading} style={{ backgroundColor: '#698B01', color: 'white' }}>
                {loading ? 'Loading...' : 'Generate Recommendation'}
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faUtensils} />
              </button>
            </div>
          </form>
          {/* Recommendation Display and Save Button */}
          <div>
            {recommendation && (
              <>
                <p>{recommendation}</p>
                <button className="button" onClick={saveRecommendationToFirestore} style={{ backgroundColor: '#698B01', color: 'white' }}>
                  Save Recommendation
                </button>
              </>
            )}
          </div>
        </div>
      </article>
    </div>
  );
};

export default MealTime;