import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import './NFTs.css';

const NFTs = () => {
  const [nfts, setNFTs] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNFTs = async () => {
      const db = getFirestore();
      const nftsCollection = collection(db, 'nfts');
      const nftsSnapshot = await getDocs(nftsCollection);
      const nftsData = nftsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNFTs(nftsData);
    };
    fetchNFTs();
  }, []);

  const handleNFTClick = (nft) => {
    setSelectedNFT(nft);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedNFT(null);
    setShowModal(false);
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faImages} /> NFTs
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="nft-cards">
            {nfts.map((nft) => (
              <div key={nft.id} className="nft-card" onClick={() => handleNFTClick(nft)}>
                <img src={nft.imageUrl} alt="NFT" className="nft-image" />
              </div>
            ))}
          </div>
        </div>
      </article>
      {showModal && selectedNFT && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content modal-content-custom">
            <div className="box">
              <div className="modal-header">
                <h2>{selectedNFT.name}</h2>
                <button className="close-button" onClick={closeModal}>
                  <FaTimes />
                </button>
              </div>
              <div className="nft-details">
                <img src={selectedNFT.imageUrl} alt="NFT" className="modal-nft-image" />
                <p>{selectedNFT.description}</p>
              </div>
              <div className="modal-buttons">
                <button className="button" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
      )}
    </div>
  );
};

export default NFTs;