import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faUtensils, faClipboardList, faShoppingCart, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const RecipeHub = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="container">
      <article className="message" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faBookOpen} />&nbsp;&nbsp;Recipe Hub
          </p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
      </article>

      <div className="columns">
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faUtensils} /> Recipes
              </p>
              <p>Explore a wide range of delicious recipes to tantalize your taste buds.</p>
              <br />
              <a href="/recipe-tools" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>

        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faClipboardList} /> Meal Plans
              </p>
              <p>Plan and organize your meals for a seamless cooking experience.</p>
              <br />
              <a href="/meal-plans" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>

        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faShoppingCart} /> Grocery Lists
              </p>
              <p>Create and manage your grocery lists for effortless shopping.</p>
              <br />
              <a href="/grocery-lists" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeHub;