import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBowlRice, faUtensils, faAppleWhole, faArrowRight, faBook } from '@fortawesome/free-solid-svg-icons';

const Sarah = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/'); // Assuming '/' is the route for the dashboard
  };

  return (
    <div className="container">
      <article className="message" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faBook} />&nbsp;&nbsp;Sarah
          </p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
      </article>
      <div className="columns">
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faBowlRice} /> Recipes
              </p>
              <p>Dive into a world of recipes that inspire your palate and enrich your cooking repertoire.</p>
              <br />
              <a href="/recipe-hub" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faUtensils} /> Restaurants
              </p>
              <p>Explore restaurants that match your vibes and culinary preferences.</p>
              <br />
              <a href="/restaurants" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faAppleWhole} /> Snacks
              </p>
              <p>Elevate your snack game with nutritious and delicious options.</p>
              <br />
              <a href="/snacks" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sarah;