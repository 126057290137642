import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faCalendarAlt,
  faUserPlus,
  faUtensils,
  faClipboardList,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './MealPlan.css';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const MealPlan = () => {
  const navigate = useNavigate();
  const [view, setView] = useState('month');
  const [showModal, setShowModal] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showRecipeModal, setShowRecipeModal] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [mealPlan, setMealPlan] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedColor, setSelectedColor] = useState('#000000');

  useEffect(() => {
    const fetchRecipes = async () => {
      const db = getFirestore();
      const recipesCollection = collection(db, 'recipes');
      const recipesSnapshot = await getDocs(recipesCollection);
      const recipesData = recipesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecipes(recipesData);
    };
    fetchRecipes();
  }, []);

  const handleGoBack = () => {
    navigate('/');
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleConnectCalendar = () => {
    setShowCalendarModal(true);
  };

  const handleCloseCalendarModal = () => {
    setShowCalendarModal(false);
  };

  const handleSyncGoogleCalendar = () => {
    // Implement Google Calendar sync functionality here
    console.log('Google Calendar sync');
    handleCloseCalendarModal();
  };

  const handleSyncOutlookCalendar = () => {
    // Implement Outlook Calendar sync functionality here
    console.log('Outlook Calendar sync');
    handleCloseCalendarModal();
  };

  const handleInvite = () => {
    // Implement invite functionality here
  };

  const handleAddRecipe = () => {
    setShowRecipeModal(true);
  };

  const handleSelectRecipe = (recipe) => {
    setSelectedRecipe(recipe);
    setShowModal(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleSaveRecipe = () => {
    if (selectedRecipe && selectedDate) {
      const newMealPlanItem = {
        recipe: selectedRecipe,
        date: selectedDate,
        color: selectedColor,
      };
      setMealPlan([...mealPlan, newMealPlanItem]);
    }
    setShowModal(false);
    setSelectedRecipe(null);
    setSelectedColor('#000000');
  };

  return (
    <div className="container">
      <article className="message" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ cursor: 'pointer' }} /> &nbsp;&nbsp;Meal
            Plan
          </p>
          <button className="button is-primary" onClick={handleInvite} style={{ backgroundColor: '#698B01' }}>
            <FontAwesomeIcon icon={faUserPlus} /> Invite
          </button>
        </div>
      </article>
      <div className="columns">
        <div className="column is-full">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faClipboardList} /> Meal Plan
              </p>
              {mealPlan.length > 0 ? (
                mealPlan.map((item, index) => (
                  <div key={index} className="meal-item">
                    <div className="meal-image-container">
                      <img
                        src={`data:image/png;base64,${item.recipe.imageData}`}
                        alt="Recipe"
                        className="meal-image"
                        style={{ width: '150px', height: '150px' }}
                      />
                    </div>
                    <div className="meal-details">
                      <p className="meal-title" style={{ color: item.color }}>
                        {item.recipe.recommendation.split('\n')[0].replace('###', '').trim()}
                      </p>
                      <p>
                        Date: {item.date.toLocaleDateString()} &nbsp;&nbsp; Time:{' '}
                        {item.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="add-recipe-container">
                  <button className="button is-primary" onClick={handleAddRecipe}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Add Recipes
                  </button>
                </div>
              )}
              {mealPlan.length > 0 && (
                <div className="add-more-recipe-container">
                  <button className="button is-primary" onClick={handleAddRecipe}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Add More Recipes
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faCalendarAlt} /> Calendar View
              </p>
              <button
                className="button is-primary"
                style={{ backgroundColor: '#698B01' }}
                onClick={handleConnectCalendar}
              >
                Connect Calendar
              </button>
              <br />
              <br />
              <Calendar view={view} onChange={handleDateChange} />
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${showModal ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setShowModal(false)}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Select Date and Color</p>
            <button className="delete" aria-label="close" onClick={() => setShowModal(false)}></button>
          </header>
          <section className="modal-card-body">
            <div className="content">
              <p>Select a date for the recipe:</p>
              <input
                type="date"
                value={selectedDate.toISOString().substring(0, 10)}
                onChange={(e) => setSelectedDate(new Date(e.target.value))}
              />
              <br />
              <br />
              <p>Select a color for the recipe:</p>
              <input type="color" value={selectedColor} onChange={(e) => handleColorChange(e.target.value)} />
            </div>
          </section>
          <footer className="modal-card-foot">
  <button className="button is-success" onClick={handleSaveRecipe}>
    Save
  </button>
  <button className="button" onClick={() => setShowModal(false)}>
    Cancel
  </button>
</footer>
</div>
</div>
<div className={`modal ${showRecipeModal ? 'is-active' : ''}`}>
  <div className="modal-background" onClick={() => setShowRecipeModal(false)}></div>
  <div className="modal-card">
    <header className="modal-card-head">
      <p className="modal-card-title">Select Recipe</p>
      <button className="delete" aria-label="close" onClick={() => setShowRecipeModal(false)}></button>
    </header>
    <section className="modal-card-body">
      <div className="content">
        <div className="recipe-list" style={{ height: '400px', overflowY: 'scroll' }}>
          {recipes.map((recipe) => (
            <div key={recipe.id} className="recipe-item" onClick={() => handleSelectRecipe(recipe)}>
              <div className="recipe-content">
                <div className="recipe-image-container">
                  <img
                    src={`data:image/png;base64,${recipe.imageData}`}
                    alt="Recipe"
                    className="recipe-image"
                    style={{ width: '150px', height: '150px' }}
                  />
                </div>
                <div className="recipe-details">
                  <p className="recipe-title">{recipe.recommendation.split('\n')[0].replace('###', '').trim()}</p>
                  <p className="recipe-source">{recipe.source}</p>
                  <div className="recipe-rating">
                    {Array(5)
                      .fill()
                      .map((_, i) => (
                        <span key={i} className="star">
                          &#9733;
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <footer className="modal-card-foot">
      <button className="button" onClick={() => setShowRecipeModal(false)}>
        Cancel
      </button>
    </footer>
  </div>
</div>
<div className={`modal ${showCalendarModal ? 'is-active' : ''}`}>
  <div className="modal-background" onClick={handleCloseCalendarModal}></div>
  <div className="modal-card">
    <header className="modal-card-head">
      <p className="modal-card-title">Connect Calendar</p>
      <button className="delete" aria-label="close" onClick={handleCloseCalendarModal}></button>
    </header>
    <section className="modal-card-body">
      <div className="content">
        <p>Choose a calendar provider to sync with:</p>
        <button className="button is-primary" onClick={handleSyncGoogleCalendar}>
          <FontAwesomeIcon icon={faGoogle} /> Sync Google Calendar
        </button>
        <button className="button is-primary" onClick={handleSyncOutlookCalendar}>
          <FontAwesomeIcon icon={faMicrosoft} /> Sync Outlook Calendar
        </button>
      </div>
    </section>
    <footer className="modal-card-foot">
      <button className="button" onClick={handleCloseCalendarModal}>
        Cancel
      </button>
    </footer>
  </div>
</div>
</div>
);
};

export default MealPlan;