import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CookingSkillWorkshops.css';

const workshopsData = [
  {
    id: 1,
    title: 'Basic Knife Skills',
    description: 'Learn the basics of knife handling to chop, slice, and dice like a pro.',
    enrolled: false
  },
  {
    id: 2,
    title: 'Mastering the Art of Baking',
    description: 'Dive into the world of baking, from bread to pastries.',
    enrolled: false
  },
  {
    id: 3,
    title: 'Vegetarian Cooking Essentials',
    description: 'Explore the fundamentals of creating delicious and satisfying vegetarian meals.',
    enrolled: false
  },
];

const CookingSkillWorkshops = () => {
  const [workshops, setWorkshops] = useState(workshopsData);
  const navigate = useNavigate();

  const handleEnrollmentToggle = (workshopId) => {
    const updatedWorkshops = workshops.map(workshop => {
      if (workshop.id === workshopId) {
        return { ...workshop, enrolled: !workshop.enrolled };
      }
      return workshop;
    });
    setWorkshops(updatedWorkshops);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <article className="message" style={{ color: '#698B01' }}>
        <div className="message-header">
          <p>Cooking Skill Workshops</p>
          <button onClick={handleGoBack} className="button is-info">Back</button>
        </div>
        <div className="message-body">
          <ul>
            {workshops.map(workshop => (
              <li key={workshop.id} className="workshop-item">
                <h3 className="title is-5">{workshop.title}</h3>
                <p>{workshop.description}</p>
                <button className={`button ${workshop.enrolled ? 'is-danger' : 'is-primary'}`} onClick={() => handleEnrollmentToggle(workshop.id)} style={{ backgroundColor: workshop.enrolled ? '#ff3860' : '#698B01' }}>
                  {workshop.enrolled ? 'Unenroll' : 'Enroll'}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </article>
    </div>
  );
};

export default CookingSkillWorkshops;