import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faCreditCard, faUser, faWrench, faBell, faGift } from '@fortawesome/free-solid-svg-icons';

const Nav = ({ user, handleLogout }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation" style={{ backgroundColor: '#fff', position: 'sticky', top: 0, zIndex: 1000 }}>
      <div className="navbar-brand">
        <a href="/" className="navbar-item" style={{ marginLeft: '1cm' }}>
          <img src="/logo512.png" alt="Logo" style={{ height: '40px', marginRight: '0.5rem' }} />
        </a>
        <div className="navbar-burger" onClick={toggleDropdown} style={{ marginRight: '1cm', marginTop: 'auto', marginBottom: 'auto' }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`navbar-menu ${dropdownVisible ? 'is-active' : ''}`}>
        <div className="navbar-end">
          <a className="navbar-item" href="/notifications">
            <FontAwesomeIcon icon={faBell} />
          </a>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link" onClick={toggleDropdown}>
              <span className="icon">
                <FontAwesomeIcon icon={faUser} />
              </span>
            </a>
            {dropdownVisible && (
              <div className="navbar-dropdown is-right is-boxed">
                <a className="navbar-item" href="/profile">
                  <FontAwesomeIcon icon={faUser} />
                  <span style={{ marginLeft: '0.5rem' }}>Profile</span>
                </a>
                <a className="navbar-item" href="/billing">
                  <FontAwesomeIcon icon={faCreditCard} />
                  <span style={{ marginLeft: '0.5rem' }}>Billing</span>
                </a>
                <a className="navbar-item" href="/settings">
                  <FontAwesomeIcon icon={faWrench} />
                  <span style={{ marginLeft: '0.5rem' }}>Settings</span>
                </a>
                <a className="navbar-item" href="/referrals">
                  <FontAwesomeIcon icon={faGift} />
                  <span style={{ marginLeft: '0.5rem' }}>Referrals</span>
                </a>
                <hr className="navbar-divider" />
                <a className="navbar-item" onClick={handleLogout} style={{ color: 'red' }}>
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  <span style={{ marginLeft: '0.5rem' }}>Logout</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;