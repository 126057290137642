import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Recipes.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBowlRice, faCarrot, faWheatAwnCircleExclamation, faFloppyDisk, faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faClock, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBKhJVJRPzIOXht2I40xNM7AsoC6W31Uuc',
  authDomain: 'saladhr-ffd61.firebaseapp.com',
  projectId: 'saladhr-ffd61',
  storageBucket: 'saladhr-ffd61.appspot.com',
  messagingSenderId: '1070443053637',
  appId: '1:1070443053637:web:28624b681b29acb95ed92c',
  measurementId: 'G-ESF78XF7SK',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const Recipes = () => {
  const [ingredients, setIngredients] = useState([]);
  const [showIngredientsDropdown, setShowIngredientsDropdown] = useState(false);
  const [cookingTime, setCookingTime] = useState('');
  const [cuisine, setCuisine] = useState('');
  const [dietary, setDietary] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [imageData, setImageData] = useState('');
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const navigate = useNavigate();

  const ingredientsOptions = [
    "Chicken", "Beef", "Fish (salmon, cod, tuna, etc.)", "Shrimp", "Tofu",
    "Quinoa", "Lentils", "Beans (black beans, kidney beans, etc.)", "Rice (white rice, brown rice, jasmine rice, etc.)",
    "Pasta (spaghetti, penne, fusilli, etc.)", "Potatoes (sweet potatoes, russet potatoes, etc.)", "Broccoli",
    "Spinach", "Carrots", "Bell peppers (red, green, yellow)", "Tomatoes", "Onions", "Garlic", "Mushrooms",
    "Zucchini", "Eggplant", "Cauliflower", "Celery", "Lettuce", "Cabbage", "Kale", "Brussels sprouts", "Asparagus", "Corn",
    "Peas", "Green beans", "Artichokes", "Beets", "Radishes", "Cucumber", "Squash (butternut squash, acorn squash, etc.)",
    "Avocado", "Mango", "Pineapple", "Berries (strawberries, blueberries, raspberries, etc.)", "Apples", "Bananas",
    "Oranges", "Grapes", "Kiwi", "Papaya", "Watermelon", "Cantaloupe", "Honeydew melon", "Lemon", "Lime", "Grapefruit",
    "Coconut", "Pomegranate", "Dates", "Figs", "Apricots", "Plums", "Cherries", "Peaches", "Pears", "Nectarines",
    "Guava", "Lychee", "Passion fruit", "Dragon fruit", "Star fruit", "Persimmon", "Jackfruit", "Durian",
    "Bread (white bread, whole wheat bread, sourdough, etc.)", "Tortillas", "Bagels", "Croissants", "English muffins",
    "Rolls (dinner rolls, sandwich rolls, etc.)", "Pita bread", "Naan", "Baguette", "Crackers", "Pretzels",
    "Chips (potato chips, tortilla chips, etc.)", "Popcorn", "Cereal", "Oats", "Granola", "Pancake mix", "Waffle mix",
    "Muffin mix", "Cake mix", "Brownie mix", "Cookie mix", "Pie crust", "Puff pastry", "Filo dough", "Pizza dough",
    "Frozen vegetables (peas, corn, broccoli, etc.)", "Frozen fruits (berries, mango, pineapple, etc.)",
    "Frozen meals (pizza, lasagna, etc.)", "Canned beans", "Canned tomatoes"
  ];

  const cookingTimeOptions = [
    "15 minutes", "30 minutes", "45 minutes", "1 hour",
  ];

  const cuisineOptions = [
    "Italian", "Mexican", "Chinese", "Japanese",
  ];

  const dietaryOptions = [
    "Vegetarian", "Vegan", "Gluten-free", "Paleo",
  ];

  const handleIngredientChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIngredients([...ingredients, value]);
    } else {
      setIngredients(ingredients.filter((ingredient) => ingredient !== value));
    }
  };

  const toggleIngredientsDropdown = () => setShowIngredientsDropdown(!showIngredientsDropdown);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const requestBody = {
        Ingredients: ingredients,
        CookingTime: cookingTime,
        Cuisine: cuisine,
        Dietary: dietary,
      };

      console.log('Request Body:', requestBody);

      const recipeResponse = await fetch('https://68wiend0z0.execute-api.eu-west-1.amazonaws.com/prod/recipes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'tKeEiRwzq91d5N3rMsxRR7RZ3pUHIReq6Kl3lTW4',
        },
        body: JSON.stringify({ body: JSON.stringify(requestBody) }),
      });

      const recipeData = await recipeResponse.json();

      if (recipeResponse.ok) {
        console.log('Recipe Response Data:', recipeData);
        const parsedRecipeData = JSON.parse(recipeData.body);

        if (parsedRecipeData.recommendation) {
          console.log('Recommendation:', parsedRecipeData.recommendation);
          setRecommendation(parsedRecipeData.recommendation);

          // Introduce a delay of 25 seconds (25000 milliseconds)
          setTimeout(async () => {
            const imagePayload = { body: JSON.stringify({ recommendation: parsedRecipeData.recommendation }) };
            console.log('Image API Payload:', imagePayload);

            const imageResponse = await fetch('https://t6pd8tplk6.execute-api.eu-west-1.amazonaws.com/prod/RecipeImage', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'tKeEiRwzq91d5N3rMsxRR7RZ3pUHIReq6Kl3lTW4',
              },
              body: JSON.stringify(imagePayload),
            });

            const imageData = await imageResponse.json();

            if (imageResponse.ok) {
              console.log('Image Response Data:', imageData);

              if (imageData.body) {
                const parsedImageData = JSON.parse(imageData.body);
                console.log('Image Data:', parsedImageData.image);
                setImageData(parsedImageData.image.artifacts[0].base64 || '');
              } else {
                console.log('Invalid image response data:', imageData);
                setImageData('');
              }
            } else {
              console.error('Error fetching image:', imageData);
              setImageData('');
            }

            setLoading(false);
          }, 25000);
        } else {
          console.log('Invalid recipe response data:', parsedRecipeData);
          setRecommendation('Invalid response from the server.');
          setImageData('');
          setLoading(false);
        }
      } else {
        console.error('Error fetching recipe:', recipeData);
        setRecommendation('Failed to fetch recommendations. Please try again.');
        setImageData('');
        setLoading(false);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setRecommendation('Failed to fetch recommendations. Please try again.');
      setImageData('');
      setLoading(false);
    }
  };

  const handleSaveRecommendation = async () => {
    setSaveLoading(true);
    setSaveStatus(null);

    try {
      const data = {
        recommendation: recommendation,
        imageData: imageData,
      };

      console.log('Sent data:', data);

      // Send the data to Firestore
      await addDoc(collection(db, 'recipes'), data);

      console.log('Recipe saved successfully');
      setSaveStatus('success');
    } catch (error) {
      console.error('Error saving recommendation:', error);
      setSaveStatus('error');
    }

    setSaveLoading(false);
  };

  const renderFormattedRecommendation = () => {
    if (!recommendation) return null;

    const lines = recommendation.split('\n');
    const title = lines[0].replace('###', '').trim();
    const ingredients = lines.slice(1, lines.findIndex(line => line.startsWith('**Instructions:**'))).join('\n');
    const instructions = lines.slice(lines.findIndex(line => line.startsWith('**Instructions:**')) + 1).join('\n');

    const formattedInstructions = instructions
      .replace(/^\d+\.\s+\*\*(.*?)\*\*/gm, '<li>$1</li>')
      .replace(/<li>/g, '<li class="instruction">');

    return (
      <div className="recommendation" id="recommendation-content">
        <h2 className="title">{title}</h2>
        {imageData && <img src={`data:image/png;base64,${imageData}`} alt="Recipe" className="recipe-image" />}
        <div className="recommendation-details">
          <h3>Ingredients:</h3>
          <div dangerouslySetInnerHTML={{ __html: ingredients }} />
          <h3>Instructions:</h3>
          <ol dangerouslySetInnerHTML={{ __html: formattedInstructions }} />
        </div>
        <div className="button-container">
          {renderSaveButton()}
          <button className="button green-button" onClick={handleDownloadPDF}>
            <FontAwesomeIcon icon={faFloppyDisk} /> Download PDF
          </button>
          <button className="button green-button" onClick={handleDownloadImage}>
            <FontAwesomeIcon icon={faDownload} /> Download Image
          </button>
        </div>
      </div>
    );
  };

  const handleDownloadPDF = async () => {
    const element = document.getElementById('recommendation-content');
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('recipe.pdf');
  };

  const handleDownloadImage = async () => {
    const element = document.getElementById('recommendation-content');
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = imgData;
    link.download = 'recipe.png';
    link.click();
  };

  const renderSaveButton = () => {
    if (!recommendation || !imageData) {
      return (
        <button className="green-button" disabled>
          <FontAwesomeIcon icon={faFloppyDisk} /> Save
        </button>
      );
    }
  
    if (saveLoading) {
      return (
        <button className="green-button is-loading" disabled>
          <FontAwesomeIcon icon={faFloppyDisk} /> Saving...
        </button>
      );
    }
  
    return (
      <button className="green-button" onClick={handleSaveRecommendation}>
        <FontAwesomeIcon icon={faFloppyDisk} /> Save
      </button>
    );
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faBowlRice} /> Recipe Recommendation
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="columns">
            <div className="column is-one-third">
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <label className="label">
                    <FontAwesomeIcon icon={faCarrot} /> Ingredients
                  </label>
                  <div className="control">
                    <button type="button" onClick={toggleIngredientsDropdown} className="button">
                      {showIngredientsDropdown ? 'Hide Ingredients' : 'Choose Ingredients'}
                    </button>
                    {showIngredientsDropdown && (
                      <div className="ingredients-dropdown">
                        {ingredientsOptions.map((option, index) => (
                          <div key={index} className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                value={option}
                                onChange={handleIngredientChange}
                                checked={ingredients.includes(option)}
                              />
                              <span className="ingredient-option">
                                <FontAwesomeIcon icon={getIngredientIcon(option)} className="ingredient-icon" />
                                {option}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">
                    <FontAwesomeIcon icon={faClock} /> Cooking Time
                  </label>
                  <div className="control">
                    <div className="select">
                      <select value={cookingTime} onChange={(e) => setCookingTime(e.target.value)}>
                        <option value="">Select Cooking Time</option>
                        {cookingTimeOptions.map((option, index) => (
                          <option key={index} value={option}>{option}</option>
                          ))}
                          </select>
                          </div>
                          </div>
                          </div>
                          <div className="field">
              <label className="label">
                <FontAwesomeIcon icon={faFolderOpen} /> Cuisine
              </label>
              <div className="control">
                <div className="select">
                  <select value={cuisine} onChange={(e) => setCuisine(e.target.value)}>
                    <option value="">Select Cuisine</option>
                    {cuisineOptions.map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label">
                <FontAwesomeIcon icon={faWheatAwnCircleExclamation} /> Dietary Preferences
              </label>
              <div className="control">
                <div className="select">
                  <select value={dietary} onChange={(e) => setDietary(e.target.value)}>
                    <option value="">Select Dietary Preferences</option>
                    {dietaryOptions.map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="control">
              <button className="button green-button" type="submit" disabled={loading}>
                {loading ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                  </>
                ) : (
                  <>
                    Get Recipe <FontAwesomeIcon icon={faBowlRice} />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="column">
          <div className="recommendation-container">
            {renderFormattedRecommendation()}
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
);
};

// Helper function to get the appropriate icon for each ingredient
const getIngredientIcon = (ingredient) => {
// Add your logic here to return the appropriate FontAwesome icon based on the ingredient
// For example:
// if (ingredient.includes('Chicken')) return faChicken;
// if (ingredient.includes('Beef')) return faBeef;
// ...
// Default icon if no specific icon is found
//return faCarrot;
};

export default Recipes;