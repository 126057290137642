import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EventPlanning.css';

const initialEvents = [
  {
    id: 1,
    title: 'Virtual Pasta Making Class',
    description: 'Join us for an interactive online pasta making class.',
    date: '2023-06-15',
    time: '18:00',
    attendees: [],
  },
  {
    id: 2,
    title: 'Farmers Market Tour',
    description: 'Explore the local farmers market and discover fresh produce.',
    date: '2023-07-10',
    time: '10:00',
    attendees: [],
  },
];

const EventPlanning = () => {
  const [events, setEvents] = useState(initialEvents);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [attendeeName, setAttendeeName] = useState('');
  const navigate = useNavigate();

  const handleEventChange = (e) => {
    const eventId = parseInt(e.target.value);
    const event = events.find((event) => event.id === eventId);
    setSelectedEvent(event);
  };

  const handleAttendeeNameChange = (e) => {
    setAttendeeName(e.target.value);
  };

  const handleAttendEvent = () => {
    if (!selectedEvent || !attendeeName) return;

    const updatedEvents = events.map((event) => {
      if (event.id === selectedEvent.id) {
        return { ...event, attendees: [...event.attendees, attendeeName] };
      }
      return event;
    });

    setEvents(updatedEvents);
    setSelectedEvent(null);
    setAttendeeName('');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <article className="message" style={{ color: '#698B01' }}>
        <div className="message-header">
          <p>Event Planning</p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
        <div className="message-body">
          <div className="field">
            <label className="label">Select Event</label>
            <div className="control">
              <div className="select">
                <select onChange={handleEventChange}>
                  <option value="">Choose an Event</option>
                  {events.map((event) => (
                    <option key={event.id} value={event.id}>
                      {event.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {selectedEvent && (
            <div className="event-details">
              <h3 className="title is-5">{selectedEvent.title}</h3>
              <p>{selectedEvent.description}</p>
              <p>
                Date: {selectedEvent.date} | Time: {selectedEvent.time}
              </p>
              <div className="field">
                <label className="label">Your Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Enter your name"
                    value={attendeeName}
                    onChange={handleAttendeeNameChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button
                    className="button is-primary"
                    onClick={handleAttendEvent}
                    style={{ backgroundColor: '#698B01' }}
                  >
                    Attend Event
                  </button>
                </div>
              </div>
              <div className="attendees">
                <strong>Attendees:</strong>
                <ul>
                  {selectedEvent.attendees.map((attendee, index) => (
                    <li key={index}>{attendee}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </article>
    </div>
  );
};

export default EventPlanning;