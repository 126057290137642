import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const RecipeGroceryList = ({ recipe }) => {
  const [ingredients, setIngredients] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGroceryList = async () => {
      setLoading(true);
      try {
        const groceryList = await generateGroceryList(recipe.recommendation);
        const parsedIngredients = parseIngredients(groceryList);
        setIngredients(parsedIngredients);
      } catch (error) {
        console.error('Error fetching grocery list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroceryList();
  }, [recipe.recommendation]);

  const parseIngredients = (groceryList) => {
    const lines = groceryList.split('\n');
    const ingredients = [];
    for (const line of lines) {
      if (line.trim() !== '') {
        ingredients.push({ item: line.trim(), count: 1 });
      }
    }
    return ingredients;
  };

  const handleIncrement = (index) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients[index].count += 1;
    setIngredients(updatedIngredients);
  };

  const handleDecrement = (index) => {
    const updatedIngredients = [...ingredients];
    if (updatedIngredients[index].count > 1) {
      updatedIngredients[index].count -= 1;
    }
    setIngredients(updatedIngredients);
  };

  const handleDelete = (index) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(index, 1);
    setIngredients(updatedIngredients);
  };

  // Vertex AI text prompts API integration
  const generateGroceryList = async (recipe) => {
    const projectId = 'saladhr-meal-ri';
    const prompt = `Please generate a grocery list of the ingredients for this recipe: ${recipe}`;
    const temperature = 0.7;
    const maxOutputTokens = 200;
    const topP = 0.9;
    const topK = 40;

    const url = `https://us-central1-aiplatform.googleapis.com/v1/projects/${projectId}/locations/us-central1/publishers/google/models/text-bison:predict`;
    const data = {
      instances: [{ prompt }],
      parameters: {
        temperature,
        maxOutputTokens,
        topP,
        topK,
      },
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 9ed031138f486dbef2f8f77cf9cdd249b68d7665`,
        },
      });

      const groceryList = response.data.predictions[0].output;
      return groceryList;
    } catch (error) {
      console.error('Error:', error.response.data);
      throw error;
    }
  };

  return (
    <div>
      {loading ? (
        <p>Loading grocery list...</p>
      ) : (
        <div className="ingredient-list">
          {ingredients.map((ingredientItem, index) => (
            <div key={index} className="ingredient-item">
              <span className="ingredient-name">{ingredientItem.item}</span>
              <div className="quantity-control">
                <button className="decrement-btn" onClick={() => handleDecrement(index)}>
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <span className="quantity-count">{ingredientItem.count}</span>
                <button className="increment-btn" onClick={() => handleIncrement(index)}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
              <button className="delete-btn" onClick={() => handleDelete(index)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecipeGroceryList;