import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import './Rewards.css';

const Rewards = () => {
  const [rewards, setRewards] = useState([]);
  const [selectedReward, setSelectedReward] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRewards = async () => {
      const db = getFirestore();
      const rewardsCollection = collection(db, 'rewards');
      const rewardsSnapshot = await getDocs(rewardsCollection);
      const rewardsData = rewardsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRewards(rewardsData);
    };
    fetchRewards();
  }, []);

  const handleRewardClick = (reward) => {
    setSelectedReward(reward);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedReward(null);
    setShowModal(false);
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faAward} /> Rewards
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="reward-cards">
            {rewards.map((reward) => (
              <div key={reward.id} className="reward-card" onClick={() => handleRewardClick(reward)}>
                <img src={reward.imageUrl} alt="Reward" className="reward-image" />
              </div>
            ))}
          </div>
        </div>
      </article>
      {showModal && selectedReward && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content modal-content-custom">
            <div className="box">
              <div className="modal-header">
                <h2>{selectedReward.name}</h2>
                <button className="close-button" onClick={closeModal}>
                  <FaTimes />
                </button>
              </div>
              <div className="reward-details">
                <img src={selectedReward.imageUrl} alt="Reward" className="modal-reward-image" />
                <p>{selectedReward.description}</p>
              </div>
              <div className="modal-buttons">
                <button className="button" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
      )}
    </div>
  );
};

export default Rewards;