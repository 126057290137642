import React from 'react';
import { DAppKitProvider } from '@vechain/dapp-kit-react';
import RecipeBook from './RecipeBook';

const VeChainApp = () => {
  return (
    <DAppKitProvider
      nodeUrl={'https://testnet.vechain.org/'}
      genesis={'test'}
      usePersistence={true}
      walletConnectOptions={{
        projectId: 'Salad-HR-v1-TESTNET',
        metadata: {
          name: 'SaladHR',
          description: 'Making food digitally interactive',
          url: 'https://app.saladhr.com',
          icons: ['https://app.saladhr.com/logo512.png'],
        },
      }}
      logLevel="DEBUG"
      themeMode="LIGHT"
    >
      <RecipeBook />
    </DAppKitProvider>
  );
};

export default VeChainApp;