import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { faAward, faMedal, faArrowRight, faTrophy, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

const Sophie = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/'); // Assuming '/' is the route for the dashboard
  };

  return (
    <div className="container">
      <article className="message" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faTrophy} />&nbsp;&nbsp;Sydney
          </p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
      </article>
      <div className="columns">
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
              <FontAwesomeIcon icon={faPeopleGroup} /> Community
              </p>
              <p>Connect with community.</p>
              <br />
              <a href="/community" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faAward} /> Rewards
              </p>
              <p>Earn rewards for eating healthy.</p>
              <br />
              <a href="/rewards" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faMedal} /> Leaderboard
              </p>
              <p>Share and discover community leaders.</p>
              <br />
              <a href="/leaderboard" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sophie;