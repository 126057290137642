import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faArrowRight, faBookOpen } from '@fortawesome/free-solid-svg-icons';

const Community = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="container">
      <article className="message" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
          <FontAwesomeIcon icon={faPeopleGroup} />&nbsp;&nbsp;Community
          </p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
      </article>

      <div className="columns">
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faPeopleGroup} /> Join a Group
              </p>
              <p>Connect with like-minded individuals and join our vibrant community.</p>
              <br />
              <a href="/join-group" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>

        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faPeopleGroup} /> Create a Group
              </p>
              <p>Start your own group and bring together people who share your interests.</p>
              <br />
              <a href="/create-group" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>

        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faPeopleGroup} /> Community Events
              </p>
              <p>Explore and participate in various community events and activities.</p>
              <br />
              <a href="/community-events" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;