import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './Snacks.css';
import SaladHRLogo from './saladhr-logo.png'; // Import the SaladHR logo image
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleWhole, faCircleCheck, faHeartPulse, faWheatAwnCircleExclamation, faBagShopping } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
  apiKey: 'AIzaSyBKhJVJRPzIOXht2I40xNM7AsoC6W31Uuc',
  authDomain: 'saladhr-ffd61.firebaseapp.com',
  projectId: 'saladhr-ffd61',
  storageBucket: 'saladhr-ffd61.appspot.com',
  messagingSenderId: '1070443053637',
  appId: '1:1070443053637:web:28624b681b29acb95ed92c',
  measurementId: 'G-ESF78XF7SK',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const Snacks = () => {
  const [tastePreference, setTastePreference] = useState('');
  const [healthFocus, setHealthFocus] = useState('');
  const [dietaryRestrictions, setDietaryRestrictions] = useState([]);
  const [convenience, setConvenience] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [imageData, setImageData] = useState('');
  const [nutritionalInfo, setNutritionalInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDietaryDropdown, setShowDietaryDropdown] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const navigate = useNavigate();
  const recommendationRef = useRef();

  const tasteOptions = ['Sweet', 'Salty', 'Sour', 'Savory'];
  const healthOptions = ['High Protein', 'High Fiber', 'Low Calorie', 'Low Sugar', 'Low Fat'];
  const dietaryOptions = ['Gluten-Free', 'Vegan', 'Dairy-Free', 'Nut-Free', 'Lactose-Free'];
  const convenienceOptions = ['Easy to Carry', 'Quick Energy Boost', 'No Preparation Needed'];

  const handleDietaryChange = (e) => {
    const { value, checked } = e.target;
    setDietaryRestrictions(prev =>
      checked ? [...prev, value] : prev.filter(item => item !== value)
    );
  };

  const toggleDietaryDropdown = () => {
    setShowDietaryDropdown(!showDietaryDropdown);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      body: JSON.stringify({
        tastePreference,
        healthFocus,
        dietaryRestrictions,
        convenience,
      })
    };

    try {
      const response = await fetch('https://fuppbtosgg.execute-api.eu-west-1.amazonaws.com/prod/snack-recommendation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'tKeEiRwzq91d5N3rMsxRR7RZ3pUHIReq6Kl3lTW4',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response:", responseData); // Log the response

      if (responseData && responseData.body) {
        const result = JSON.parse(responseData.body);
        setRecommendation(result.recommendation);
        setImageData(result.image.artifacts[0].base64 || '');
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Fetch error:', error.message);
      setRecommendation('Failed to fetch recommendations. Please try again.');
      setImageData('');
    } finally {
      setLoading(false);
    }
  };

  const handleNutritionalInfoClick = async () => {
    if (!imageData) {
      console.log('No image available.');
      return;
    }

    setLoading(true);

    try {
      const blob = await fetch(`data:image/png;base64,${imageData}`).then((res) => res.blob());
      const file = new File([blob], 'image.jpeg', { type: 'image/jpeg' });

      const formData = new FormData();
      formData.append('image', file);

      const segmentationResponse = await axios.post(
        'https://api.logmeal.es/v2/image/segmentation/complete',
        formData,
        {
          headers: {
            'Authorization': 'Bearer 637f87e57de27fa1c882cb2ba3729555d00d7a26',
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const imageId = segmentationResponse.data.imageId;

      const nutritionalInfoResponse = await axios.post(
        'https://api.logmeal.es/v2/recipe/nutritionalInfo',
        { imageId },
        {
          headers: {
            'Authorization': 'Bearer 09895fec008e2345a048cb9197d9a11eac5496eb',
            'Content-Type': 'application/json',
          },
        }
      );

      setNutritionalInfo(nutritionalInfoResponse.data);
    } catch (error) {
      console.error('Error:', error);
    }

    setLoading(false);
  };

  const handleDownloadPDF = async () => {
    const element = recommendationRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

    // Add SaladHR logo and "Generated by SaladHR powered by OpenAI & Stability.AI" text
    const logoWidth = 50;
    const logoHeight = 50;
    pdf.addImage(SaladHRLogo, 'PNG', pdfWidth - logoWidth - 10, pdfHeight - logoHeight - 10, logoWidth, logoHeight);
    pdf.setFontSize(12);
    pdf.text('Generated by SaladHR powered by OpenAI & Stability.AI', 10, pdfHeight - 10);

    pdf.save('snack-recommendation.pdf');
  };

  const handleDownloadImage = async () => {
    const element = recommendationRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    // Create a new canvas to add the SaladHR logo and text
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      tempCanvas.width = img.width;
      tempCanvas.height = img.height + 60; // Add space for the logo and text
      tempCtx.drawImage(img, 0, 0);

      // Add SaladHR logo
      const logoImg = new Image();
      logoImg.onload = () => {
        const logoWidth = 50;
        const logoHeight = 50;
        tempCtx.drawImage(logoImg, tempCanvas.width - logoWidth - 10, tempCanvas.height - logoHeight - 10, logoWidth, logoHeight);

        // Add "Generated by SaladHR powered by OpenAI & Stability.AI" text
        tempCtx.font = '12px Arial';
        tempCtx.fillStyle = 'black';
        tempCtx.fillText('Generated by SaladHR powered by OpenAI & Stability.AI', 10, tempCanvas.height - 10);

        // Convert the canvas to data URL and create a download link
        const tempData = tempCanvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = tempData;
        link.download = 'snack-recommendation.png';
        link.click();
      };
      logoImg.src = SaladHRLogo;
    };
    img.src = data;
  };

  const handleSaveRecommendation = async () => {
    setSaveLoading(true);
    setSaveStatus(null);

    try {
      const data = {
        recommendation: recommendation,
        imageData: imageData,
      };

      console.log('Sent data:', data);

      // Send the data to Firestore
      await addDoc(collection(db, 'snacks'), data);

      console.log('Snack saved successfully');
      setSaveStatus('success');
    } catch (error) {
      console.error('Error saving recommendation:', error);
      setSaveStatus('error');
    }

    setSaveLoading(false);
  };

  const renderFormattedRecommendation = () => {
    if (!recommendation) return null;

    const lines = recommendation.split('\n');
    const formattedRecommendation = lines.map((line, index) => <p key={index}>{line}</p>);

    return (
      <div ref={recommendationRef}>
        {imageData && <img src={`data:image/png;base64,${imageData}`} alt="Snack" style={{ maxWidth: '100%', marginBottom: '10px' }} />}
        <div className="recommendation-container">
          {formattedRecommendation}
          {nutritionalInfo && (
            <div className="nutritional-info">
              <h3>Nutritional Information</h3>
              {renderNutritionalInfo()}
            </div>
          )}
          <div className="button-container">
            {renderSaveButton()}
            <button className="button is-link" onClick={handleNutritionalInfoClick} disabled={loading}>
              {loading ? 'Analyzing...' : 'View Nutrition'}
            </button>
            <button className="button is-link" onClick={handleDownloadPDF}>
              Download PDF
            </button>
            <button className="button is-link" onClick={handleDownloadImage}>
              Download Image
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderNutritionalInfo = () => {
    if (!nutritionalInfo) return null;

    const { nutritional_info } = nutritionalInfo;
    const { calories, totalNutrients } = nutritional_info;

    const selectedNutrients = [
      { key: 'CHOCDF', label: 'Carbs', unit: 'g', color: '#F6D55C' },
      { key: 'PROCNT', label: 'Protein', unit: 'g', color: '#ED553B' },
      { key: 'FAT', label: 'Fat', unit: 'g', color: '#3CAEA3' },
      { key: 'FIBTG', label: 'Fiber', unit: 'g', color: '#20639B' },
      { key: 'SUGAR', label: 'Sugars', unit: 'g', color: '#F6D55C' },
      { key: 'VITA_RAE', label: 'Vitamin A', unit: 'µg', color: '#ED553B' },
      { key: 'VITC', label: 'Vitamin C', unit: 'mg', color: '#3CAEA3' },
      { key: 'CA', label: 'Calcium', unit: 'mg', color: '#20639B' },
      { key: 'FE', label: 'Iron', unit: 'mg', color: '#F6D55C' },
    ];

    return (
      <table className="nutrient-table">
        <tbody>
          <tr>
            <td className="nutrient-label">Calories:</td>
            <td className="nutrient-value">{calories.toFixed(2)}</td>
          </tr>
          {selectedNutrients.map(({ key, label, unit, color }) => {
            const nutrient = totalNutrients[key];
            if (!nutrient) return null;
            return (
              <tr key={key}>
                <td className="nutrient-label" style={{ backgroundColor: color, color: 'white' }}>
                  {label}:
                </td>
                <td className="nutrient-value">{`${nutrient.quantity.toFixed(2)} ${unit}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderSaveButton = () => {
    if (!recommendation || !imageData) {
      return (
        <button className="button" disabled style={{ backgroundColor: '#698B01', color: 'white' }}>
          Save
        </button>
      );
    }

    if (saveLoading) {
      return (
        <button className="button is-loading" disabled style={{ backgroundColor: '#698B01', color: 'white' }}>
          Saving...
        </button>
      );
    }

    return (
      <button className="button" onClick={handleSaveRecommendation} style={{ backgroundColor: '#698B01', color: 'white' }}>
        Save
      </button>
    );
  };

  return (
    <div className="container">
    <article className="message">
      <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <p>
          <FontAwesomeIcon icon={faAppleWhole} /> Snack Recommendations
        </p>
        <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
      </div>
      <div className="message-body" style={{ backgroundColor: 'white' }}>
        <div className="columns">
          <div className="column is-one-third">
            <form onSubmit={handleSubmit}>
              {/* Taste and Flavor Preference */}
              <div className="field">
                <label className="label">
                  <FontAwesomeIcon icon={faCircleCheck} /> Taste and Flavor Preference:
                </label>
                <div className="control">
                  <div className="select">
                    <select value={tastePreference} onChange={e => setTastePreference(e.target.value)}>
                      <option value="">Select Preference</option>
                      {tasteOptions.map(option => <option key={option} value={option}>{option}</option>)}
                    </select>
                  </div>
                </div>
              </div>

              {/* Health and Nutrition Focus */}
              <div className="field">
                <label className="label">
                  <FontAwesomeIcon icon={faHeartPulse} /> Health and Nutrition Focus:
                </label>
                <div className="control">
                  <div className="select">
                    <select value={healthFocus} onChange={e => setHealthFocus(e.target.value)}>
                      <option value="">Select Focus</option>
                      {healthOptions.map(option => <option key={option} value={option}>{option}</option>)}
                    </select>
                  </div>
                </div>
              </div>

              {/* Dietary Restrictions and Allergies */}
              <div className="field">
                <label className="label">
                  <FontAwesomeIcon icon={faWheatAwnCircleExclamation} /> Dietary Restrictions and Allergies:
                </label>
                <div className="control">
                  <button type="button" onClick={toggleDietaryDropdown} className="button">
                    {showDietaryDropdown ? 'Hide Options' : 'Show Options'}
                  </button>
                  {showDietaryDropdown && (
                    <div className="dietary-dropdown">
                      {dietaryOptions.map(option => (
                        <label key={option} className="checkbox">
                          <input
                            type="checkbox"
                            value={option}
                            onChange={handleDietaryChange}
                            checked={dietaryRestrictions.includes(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Convenience and Portability */}
              <div className="field">
                <label className="label">
                  <FontAwesomeIcon icon={faBagShopping} /> Convenience and Portability:
                </label>
                <div className="control">
                  <div className="select">
                    <select value={convenience} onChange={e => setConvenience(e.target.value)}>
                      <option value="">Select Option</option>
                      {convenienceOptions.map(option => <option key={option} value={option}>{option}</option>)}
                    </select>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="control">
                <button className="button" type="submit" disabled={loading} style={{ backgroundColor: '#698B01', color: 'white' }}>
                  {loading ? 'Loading...' : 'Get Recommendations'}
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faAppleWhole} />
                </button>
              </div>
            </form>
          </div>
          <div className="column">
            <div className="recommendation-container">
              {renderFormattedRecommendation()}
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
  );
};

export default Snacks;