import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DAppKitProvider, useWallet, useConnex, WalletButton } from '@vechain/dapp-kit-react';
import { ConnexProvider } from '@vechain/connex';
import './RecipeNFT.css';

const RecipeNFT = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const recipe = location.state ? location.state.recipe : null;
  const [additionalInfo1, setAdditionalInfo1] = useState('');
  const [additionalInfo2, setAdditionalInfo2] = useState('');
  const { account, setSource } = useWallet();
  const { thor, vendor } = useConnex();

  useEffect(() => {
    if (account) {
      // Set the wallet source here if needed
      // setSource('walletSource');
    }
  }, [account, setSource]);

  const handleMintNFT = async () => {
    if (!account) {
      console.error('Please connect your wallet first.');
      return;
    }

    try {
      console.log('Minting NFT with the following data:', {
        ...recipe,
        additionalInfo1,
        additionalInfo2,
      });

      // Implement the NFT minting logic using thor and vendor
      // Example:
      // const nftContract = thor.account('nftContractAddress').method('mintNFT').asClause(...);
      // const result = await thor.sendTx({ ...nftContract });
      // console.log('NFT minted successfully!', result);

      // After minting the NFT, you can navigate to a success page or display a message
    } catch (error) {
      console.error('Error minting NFT:', error);
    }
  };

  if (!recipe) {
    return (
      <div>
        <p>No recipe data found. Please select a recipe from the Recipe Book.</p>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    );
  }

  return (
    <ConnexProvider>
      <DAppKitProvider
        nodeUrl={'https://testnet.vechain.org/'}
        genesis={'test'}
        usePersistence={true}
        walletConnectOptions={{
          projectId: 'Salad-HR-v1-TESTNET',
          metadata: {
            name: 'SaladHR',
            description: 'Making food digitally interactive',
            url: 'https://app.saladhr.com',
            icons: ['https://app.saladhr.com/logo512.png'],
          },
        }}
        logLevel="DEBUG"
        themeMode="LIGHT"
      >
        <div className="recipe-nft">
          <div className="recipe-nft-header">
            <h2>Mint NFT</h2>
            <button onClick={() => navigate(-1)} className="close-button">
              ×
            </button>
          </div>
          <div className="recipe-details">
            <img
              src={`data:image/png;base64,${recipe.imageData}`}
              alt="Recipe"
              className="recipe-image"
            />
            <div dangerouslySetInnerHTML={{ __html: recipe.recommendation.replace(/\n/g, '<br/>') }} />
          </div>
          <div className="additional-info">
            <label>
              Additional Info #1:
              <input
                type="text"
                value={additionalInfo1}
                onChange={(e) => setAdditionalInfo1(e.target.value)}
              />
            </label>
            <label>
              Additional Info #2:
              <input
                type="text"
                value={additionalInfo2}
                onChange={(e) => setAdditionalInfo2(e.target.value)}
              />
            </label>
          </div>
          <WalletButton />
          {account ? (
            <button className="mint-nft-btn" onClick={handleMintNFT}>
              Mint NFT
            </button>
          ) : (
            <p>Please connect your wallet to mint the NFT.</p>
          )}
        </div>
      </DAppKitProvider>
    </ConnexProvider>
  );
};

export default RecipeNFT;