import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faShoppingCart, faAppleAlt } from '@fortawesome/free-solid-svg-icons';
import './RecipeBook.css';

const RecipeBook = () => {
  const [recipes, setRecipes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipes = async () => {
      const db = getFirestore();
      const recipesCollection = collection(db, 'recipes');
      const recipesSnapshot = await getDocs(recipesCollection);
      const recipesData = recipesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecipes(recipesData);
    };
    fetchRecipes();
  }, []);

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedRecipe(null);
    setShowModal(false);
  };

  const handleGroceryListClick = (recipe) => {
    navigate(`/grocery-list/${recipe.id}`);
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faBook} /> Recipe Book
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="recipe-cards">
            {recipes.map((recipe) => (
              <div key={recipe.id} className="recipe-card" onClick={() => handleRecipeClick(recipe)}>
                <img src={`data:image/png;base64,${recipe.imageData}`} alt="Recipe" className="recipe-image" />
                <div className="recipe-details">
                  <h3>{recipe.recommendation.split('\n')[0].replace('###', '').trim()}</h3>
                  <div className="recipe-buttons">
                    <button className="grocery-list-btn" onClick={(e) => {
                      e.stopPropagation();
                      handleGroceryListClick(recipe);
                    }}>
                      <FontAwesomeIcon icon={faShoppingCart} /> Grocery List
                    </button>
                    <button className="nutritional-info-btn">
                      <FontAwesomeIcon icon={faAppleAlt} /> Nutritional Information
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </article>
      {showModal && selectedRecipe && (
        <div className="modal is-active">
          {/* Modal content */}
        </div>
      )}
    </div>
  );
};

export default RecipeBook;