import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CookingChallenges.css';

const initialChallenges = [
  {
    id: 1,
    title: '30-Minute Meals',
    description: 'Show off your best dishes that can be prepared in 30 minutes or less!',
    submissions: [],
  },
  {
    id: 2,
    title: 'Vegan Delights',
    description: 'Share your favorite vegan recipes and creations.',
    submissions: [],
  },
];

const CookingChallenges = () => {
  const [challenges, setChallenges] = useState(initialChallenges);
  const [submission, setSubmission] = useState({ challengeId: '', content: '' });
  const navigate = useNavigate();

  const handleSubmissionChange = (e) => {
    setSubmission({ ...submission, content: e.target.value });
  };

  const handleChallengeChange = (e) => {
    setSubmission({ ...submission, challengeId: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!submission.challengeId || !submission.content) return;

    const updatedChallenges = challenges.map(challenge => {
      if (challenge.id === parseInt(submission.challengeId)) {
        return { ...challenge, submissions: [...challenge.submissions, submission.content] };
      }
      return challenge;
    });

    setChallenges(updatedChallenges);
    setSubmission({ challengeId: '', content: '' });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <article className="message" style={{ color: '#698B01' }}>
        <div className="message-header">
          <p>Cooking Challenges</p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
        <div className="message-body">
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Select Challenge</label>
              <div className="control">
                <div className="select">
                  <select value={submission.challengeId} onChange={handleChallengeChange}>
                    <option value="">Choose a Challenge</option>
                    {challenges.map(challenge => (
                      <option key={challenge.id} value={challenge.id}>{challenge.title}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Your Submission</label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Describe your dish or share a link to your recipe."
                  value={submission.content}
                  onChange={handleSubmissionChange}
                ></textarea>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  style={{ backgroundColor: '#698B01' }}
                >
                  Submit Entry
                </button>
              </div>
            </div>
          </form>
          <div className="challenges-list">
            {challenges.map(challenge => (
              <div key={challenge.id} className="box challenge-box">
                <h3 className="title is-5">{challenge.title}</h3>
                <p>{challenge.description}</p>
                <div className="submissions">
                  <strong>Submissions:</strong>
                  <ul>
                    {challenge.submissions.map((submission, index) => (
                      <li key={index}>{submission}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </article>
    </div>
  );
};

export default CookingChallenges;