import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import './Referrals.css';

const Referrals = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/'); // Assuming '/' is the route for the dashboard
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faGift} /> Referrals
          </p>
          <button onClick={handleGoBack} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="referral-content">
            <h2>Refer a Friend</h2>
            <p>Invite your friends to join our platform and earn rewards!</p>
            <div className="referral-link">
              <input type="text" value="https://example.com/referral/your-unique-code" readOnly />
              <button>Copy Link</button>
            </div>
            <div className="referral-stats">
              <div className="stat-item">
                <span className="stat-label">Referrals</span>
                <span className="stat-value">10</span>
              </div>
              <div className="stat-item">
                <span className="stat-label">Rewards Earned</span>
                <span className="stat-value">$50</span>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Referrals;