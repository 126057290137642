import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

const CommunityEvents = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="container">
      <article className="message" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ cursor: 'pointer' }} />
            &nbsp;&nbsp;Community Events
          </p>
        </div>
      </article>

      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faPeopleGroup} /> Community Events
              </p>
              <p>Discover and participate in various community events and activities.</p>
              {/* Add your community events functionality here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityEvents;