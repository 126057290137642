import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faCameraRotate, faUpload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Webcam from "react-webcam";
import './Recipes.css';

const ImageToRecipe = () => {
  const [imageBase64, setImageBase64] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recipe, setRecipe] = useState('');
  const [showCameraPreview, setShowCameraPreview] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result.split(',')[1];
      setImageBase64(base64Data);
      setImagePreview(reader.result); // directly use reader.result for preview
      console.log('imageBase64 set:', base64Data); // Log the base64 data
    };
    reader.readAsDataURL(file);
  };

  const handleTakePicture = () => {
    setShowCameraPreview(false);
    const imageSrc = videoRef.current.getScreenshot();
    const base64Data = imageSrc.split(',')[1];
    setImageBase64(base64Data);
    setImagePreview(imageSrc);
    console.log('imageBase64 set:', base64Data); // Log the base64 data
  };

  const handleGenerateRecipe = async () => {
    setLoading(true);
    setRecipe('');

    try {
      const payload = { imageBase64 };
      console.log('Payload sent to AWS:', payload); // Log the payload

      const response = await fetch('https://vs26pks5c5.execute-api.eu-west-1.amazonaws.com/prod/upload-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'tKeEiRwzq91d5N3rMsxRR7RZ3pUHIReq6Kl3lTW4',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.text();
      console.log('Response from AWS:', data);
      setRecipe(data);
    } catch (error) {
      console.error('Error generating recipe:', error);
    }

    setLoading(false);
  };

  const toggleCameraPreview = () => {
    setShowCameraPreview(!showCameraPreview);
  };

  const handleSwitchCamera = () => {
    setFacingMode(facingMode === "user" ? "environment" : "user");
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faCamera} /> Image to Recipe
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="columns">
            <div className="column is-one-third">
              <div className="field">
                <label className="label">
                  <FontAwesomeIcon icon={faCamera} /> Camera
                </label>
                <div className="control">
                  <Webcam
                    audio={false}
                    screenshotFormat="image/png"
                    videoConstraints={{ facingMode: facingMode }}
                    ref={videoRef}
                    style={{ display: showCameraPreview ? 'block' : 'none' }}
                  />
                  <button className="button green-button" onClick={toggleCameraPreview}>
                    <FontAwesomeIcon icon={faCamera} />{' '}
                    {showCameraPreview ? 'Hide Camera Preview' : 'Show Camera Preview'}
                  </button>
                  {showCameraPreview && (
                    <>
                      <button className="button green-button" onClick={handleTakePicture}>
                        <FontAwesomeIcon icon={faCamera} /> Take Picture
                      </button>
                      <button className="button green-button" onClick={handleSwitchCamera}>
                        <FontAwesomeIcon icon={faCameraRotate} /> Switch Camera
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <FontAwesomeIcon icon={faUpload} /> Upload Image
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
              {imagePreview && (
                <div className="field">
                  <label className="label">Preview:</label>
                  <div className="control">
                    <img src={imagePreview} alt="Preview" className="preview-image" />
                  </div>
                </div>
              )}
              <div className="control">
                <button
                  className="button green-button"
                  onClick={handleGenerateRecipe}
                  disabled={!imageBase64 || loading}
                >
                  {loading ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                    </>
                  ) : (
                    <>
                      Generate Recipe <FontAwesomeIcon icon={faCamera} />
                    </>
                  )}
                </button>
              </div>
            </div>
            <div className="column">
              <div className="recommendation-container">
                {recipe && (
                  <div className="recommendation" id="recommendation-content">
                    <h2 className="title">Recipe</h2>
                    <div dangerouslySetInnerHTML={{ __html: recipe }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default ImageToRecipe;
