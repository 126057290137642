import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useCallback } from 'react';
import { useDescope, useSession, useUser } from '@descope/react-sdk';
import { Descope } from '@descope/react-sdk';
import { getSessionToken } from '@descope/react-sdk';
import Dashboard from './Dashboard';
import Nav from './Nav';
import Sarah from './Sarah';
import MealTime from './MealTime';
import Recipes from './Recipes';
import Snacks from './Snakcs';
import Scarlet from './Scarlet';
import Sophie from './Sophie';
import TeamMealPlanning from './TeamMealPlanning';
import GroupGroceryShopping from './GroupGroceryShopping';
import CookingSkillWorkshops from './CookingSkillWorkshops';
import EventPlanning from './EventPlanning';
import CookingChallenges from './CookingChallenges';
import CommunityRecipeSwap from './CommunityRecipeSwap';
import RecipeBook from './RecipeBook';
import SnackBook from './SnackBook';
import RestaurantBookmark from './RestaurantBookmark';
import RecipeNFT from './RecipeNFT';
import VeChainApp from './VeChain';
import Success from './Success';
import Cancel from './Cancel';
import NFTs from './NFTs';
import Rewards from './Rewards';
import Leaderboard from './Leaderboard';
import Settings1 from './Settings';
import Profile from './Profile';
import Referrals from './Referrals';
import RecipeGroceryList from './RecipeGroceryList';
import RecipeGroceryListPage from './RecipeGroceryListPage';
import GroceryListItem from './GroceryListItem';
import GroceryListTotalPricePage from './GroceryListTotalPricePage';
import Restaurant from './Restaurant';


// Import Bulma CSS
import 'bulma/css/bulma.min.css';
import Billing from './Billing';
import Settings from './Settings';
import GroupBookings from './GroupBookings';
import Community from './Community';
import Calendar from './Calendar';
import JoinGroup from './JoinGroup';
import CreateGroup from './CreateGroup';
import CommunityEvents from './CommunityEvents';
import RecipeHub from './RecipeHub';
import MealPlan from './MealPlan';
import RecipeTools from './RecipeTools';
import ImageToRecipe from './ImageToRecipe';

const App = () => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const { logout } = useDescope();

  const exampleFetchCall = async () => {
    const sessionToken = getSessionToken();

    // example fetch call with authentication header
    fetch('your_application_server_url', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + sessionToken,
      },
    });
  };

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Router>
      <div style={{ paddingBottom: '2rem' }}>
        {/* Conditional rendering of Nav component */}
        {isAuthenticated && <Nav user={user} handleLogout={handleLogout} />}
        <div style={{ padding: '2rem' }}>
          <Routes>
            <Route path="/" element={isAuthenticated ? <Dashboard user={user} /> : null} />
            <Route path="/sarah" element={isAuthenticated ? <Sarah /> : null} />
            <Route path="/mealtime" element={isAuthenticated ? <MealTime /> : null} />
            <Route path="/recipes" element={isAuthenticated ? <Recipes /> : null} />
            <Route path="/snacks" element={isAuthenticated ? <Snacks /> : null} />
            <Route path="/seth" element={isAuthenticated ? <Scarlet /> : null} />
            <Route path="/sydney" element={isAuthenticated ? <Sophie /> : null} />
            <Route path="/team-meal-planning" element={isAuthenticated ? <TeamMealPlanning /> : null} />
            <Route path="/group-grocery-shopping" element={isAuthenticated ? <GroupGroceryShopping /> : null} />
            <Route path="/cooking-skills-workshop" element={isAuthenticated ? <CookingSkillWorkshops /> : null} />
            <Route path="/event-planning" element={isAuthenticated ? <EventPlanning /> : null} />
            <Route path="/cooking-challenges" element={isAuthenticated ? <CookingChallenges /> : null} />
            <Route path="/community-recepie-swap" element={isAuthenticated ? <CommunityRecipeSwap /> : null} />
            <Route path="/recipe-book" element={isAuthenticated ? <VeChainApp /> : null} />
            <Route path="/snack-book" element={isAuthenticated ? <SnackBook /> : null} />
            <Route path="/restaurant-bookmark" element={isAuthenticated ? <RestaurantBookmark/> : null} />
            <Route path="/recipe-nft" element={isAuthenticated ? <RecipeNFT/> : null} />
            <Route path="/billing" element={isAuthenticated ? <Billing/> : null} />
            <Route path="/cancel" element={isAuthenticated ? <Cancel/> : null} />
            <Route path="/success" element={isAuthenticated ? <Success/> : null} />
            <Route path="/NFTs" element={isAuthenticated ? <NFTs/> : null} />
            <Route path="/rewards" element={isAuthenticated ? <Rewards/> : null} />
            <Route path="/leaderboard" element={isAuthenticated ? <Leaderboard/> : null} />
            <Route path="/settings" element={isAuthenticated ? <Settings1/> : null} />
            <Route path="/profile" element={isAuthenticated ? <Profile/> : null} />
            <Route path="/referrals" element={isAuthenticated ? <Referrals/> : null} />
            <Route path="/recipe-grocery-list" element={isAuthenticated ? <RecipeGroceryList /> : null} />
            <Route path="/grocery-list/:id" element={<RecipeGroceryListPage />} />
            <Route path="/grocery-list-item" element={<GroceryListItem />} />
            <Route path="/grocery-list/:id/total-price" element={<GroceryListTotalPricePage />} />
            <Route path="/restaurants" element={<Restaurant/>} />
            <Route path="/group-bookings" element={<GroupBookings/>} />
            <Route path="/calendar" element={<Calendar/>} />
            <Route path="/community" element={<Community/>} />
            <Route path="/join-group" element={<JoinGroup/>} />
            <Route path="/create-group" element={<CreateGroup/>} />
            <Route path="/community-events" element={<CommunityEvents/>} />
            <Route path="/recipe-hub" element={<RecipeHub/>} />
            <Route path="/meal-plans" element={<MealPlan/>} />
            <Route path="/recipe-tools" element={isAuthenticated ? <RecipeTools/> : null} />
            <Route path="/image-to-recipes" element={isAuthenticated ? <ImageToRecipe/> : null} />
          </Routes>
          {/* Conditional rendering of Descope component */}
          {!isAuthenticated && (
            <Descope
              flowId="sign-up-or-in"
              onSuccess={(e) => console.log(e.detail.user)}
              onError={(e) => console.log('Could not log in!')}
            />
          )}
          {/* Loading indicator */}
          {(isSessionLoading || isUserLoading) && <p>Loading...</p>}
        </div>
        <footer
          className="footer"
          style={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: '10px',
            backgroundColor: '#fff',
            color: '#4f4f4f',
            padding: '0.5rem',
            textAlign: 'center',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: 1000,
          }}
        >
         <p>
          © 2024 SALAD HR TECHNOLOGY LTD <br></br>
            Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS |
            Company Number: 14979493 
          <br></br>
           Made with 💚 from London, United Kingdom.
        </p>
        </footer>
      </div>
    </Router>
  );
};

export default App;