import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookBookmark, faArrowRight, faBookmark } from '@fortawesome/free-solid-svg-icons';

const Scarlet = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/'); // Assuming '/' is the route for the dashboard
  };

  return (
    <div className="container">
      <article className="message" style={{ backgroundColor: '#698B01', color: 'white' }}>
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faBookmark} />&nbsp;&nbsp;Seth
          </p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
      </article>
      <div className="columns">
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faBook} /> Recipe Book
              </p>
              <p>All your recipe recommendations stored here.</p>
              <br />
              <a href="/recipe-book" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faBookBookmark} /> Spot Marker
              </p>
              <p>All your fav food spots and restuarants stored here.</p>
              <br />
              <a href="/restaurant-bookmark" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
        <div className="column is-one-third">
          <div className="card">
            <div className="card-content">
              <p className="title">
                <FontAwesomeIcon icon={faBook} /> Snack Book
              </p>
              <p>All your snack recommendations stored here.</p>
              <br />
              <a href="/snack-book" className="button is-primary" style={{ backgroundColor: '#698B01' }}>
                Go&nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scarlet;