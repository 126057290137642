import React from 'react';

const GroceryListItem = ({ item }) => {
  return (
    <div className="grocery-list-item">
      <label className="checkbox">
        <input type="checkbox" />
        {item}
      </label>
    </div>
  );
};

export default GroceryListItem;