import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import './Billing.css';

const Billing = () => {
  const [isYearly, setIsYearly] = useState(false);

  const getPriceText = (price) => {
    return isYearly ? `£${(price * 10).toFixed(2)} Per year` : `£${price.toFixed(2)} Per month`;
  };

  const handleCheckout = (url) => {
    window.location.href = url;
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faCreditCard} /> Billing
          </p>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="pricing-container">
            <div className="pricing-switch">
              <span>Monthly</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isYearly}
                  onChange={() => setIsYearly(!isYearly)}
                />
                <span className="slider round" style={{ backgroundColor: 'gray' }}></span>
              </label>
              <span>Yearly</span>
            </div>
            <div className="pricing-table">
              <div className="pricing-card">
                <div className="pricing-header">
                  <h2>Free</h2>
                  <p>Software as a service (SaaS) - personal use</p>
                  <h3>{getPriceText(0)}</h3>
                </div>
                <div className="pricing-features">
                  <ul>
                    <li>UNLIMITED agents</li>
                    <li>2 recipe recommendations</li>
                    <li>2 restaurant recommendations</li>
                    <li>2 snack recommendations</li>
                  </ul>
                </div>
                <div className="pricing-footer">
                  <button className="pricing-button" onClick={() => handleCheckout('https://buy.stripe.com/7sIaI2el50bg5mU28c')}>
                    Subscribe
                  </button>
                </div>
              </div>
              <div className="pricing-card">
                <div className="pricing-header">
                  <h2>Silver</h2>
                  <p>Software as a service (SaaS) - personal use</p>
                  <h3>{getPriceText(2.99)}</h3>
                </div>
                <div className="pricing-features">
                  <ul>
                    <li>Everything in Free and...</li>
                    <li>5 recipe recommendations</li>
                    <li>5 restaurant recommendations</li>
                    <li>5 snack recommendations</li>
                  </ul>
                </div>
                <div className="pricing-footer">
                  <button className="pricing-button" onClick={() => handleCheckout('https://buy.stripe.com/14kcQa3Gr5vAdTq28b')}>
                    Subscribe
                  </button>
                </div>
              </div>
              <div className="pricing-card">
                <div className="pricing-header">
                  <h2>Gold</h2>
                  <p>Software as a service (SaaS) - personal use</p>
                  <h3>{getPriceText(4.99)}</h3>
                </div>
                <div className="pricing-features">
                  <ul>
                    <li>Everything in Silver and...</li>
                    <li>15 recipe recommendations</li>
                    <li>15 restaurant recommendations</li>
                    <li>15 snack recommendations</li>
                  </ul>
                </div>
                <div className="pricing-footer">
                  <button className="pricing-button" onClick={() => handleCheckout('https://buy.stripe.com/bIYaI2el50bg9Da4gi')}>
                    Subscribe
                  </button>
                </div>
              </div>
              <div className="pricing-card">
                <div className="pricing-header">
                  <h2>Platinum</h2>
                  <p>Software as a service (SaaS) - personal use</p>
                  <h3>{getPriceText(9.99)}</h3>
                </div>
                <div className="pricing-features">
                  <ul>
                    <li>Everything in Gold and...</li>
                    <li>25 recipe recommendations</li>
                    <li>25 restaurant recommendations</li>
                    <li>25 snack recommendations</li>
                  </ul>
                </div>
                <div className="pricing-footer">
                  <button className="pricing-button" onClick={() => handleCheckout('https://buy.stripe.com/28o6rMgtdbTYaHe5kl')}>
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Billing;