import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import './RecipeGroceryList.css';
import 'bulma/css/bulma.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faBook, faSpinner, faDollarSign, faPlusCircle, faMinusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKhJVJRPzIOXht2I40xNM7AsoC6W31Uuc",
  authDomain: "saladhr-ffd61.firebaseapp.com",
  projectId: "saladhr-ffd61",
  storageBucket: "saladhr-ffd61.appspot.com",
  messagingSenderId: "1070443053637",
  appId: "1:1070443053637:web:28624b681b29acb95ed92c",
  measurementId: "G-ESF78XF7SK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const RecipeGroceryListPage = () => {
  const { id } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [groceryList, setGroceryList] = useState([]);
  const [isGeneratingList, setIsGeneratingList] = useState(false);
  const [checkedIngredients, setCheckedIngredients] = useState([]);
  const [showTotalButton, setShowTotalButton] = useState(false);
  const [bestMatches, setBestMatches] = useState({});
  const [ingredientCounts, setIngredientCounts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipe = async () => {
      const docRef = doc(db, 'recipes', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setRecipe(docSnap.data());
      } else {
        console.log('No such document!');
      }
      setLoading(false);
    };
    fetchRecipe();
  }, [id, db]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!recipe) {
    return <div>Recipe not found</div>;
  }

  const handleGenerateListClick = async () => {
    setIsGeneratingList(true);
    const apiUrl = 'https://7y4tbn4rqc.execute-api.eu-west-1.amazonaws.com/prod/grocery-list';
    const apiKey = 'tKeEiRwzq91d5N3rMsxRR7RZ3pUHIReq6Kl3lTW4';

    const requestBody = {
      Recipe: recipe.recommendation,
    };

    console.log('Request Body:', requestBody);

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    console.log('Response Data:', data);

    if (response.ok) {
      const groceryListData = JSON.parse(data.body).groceryList;
      const formattedGroceryList = parseGroceryList(groceryListData);
      setGroceryList(formattedGroceryList);
      setShowTotalButton(true);
    } else {
      console.error('Error:', data.error || data.body);
    }

    setIsGeneratingList(false);
  };

  const parseGroceryList = (groceryListData) => {
    const lines = groceryListData.split('\n');
    const formattedList = [];

    for (const line of lines) {
      const [item, price] = line.split(':');
      if (item.trim() && price) {
        const [quantity, unit, pricePerUnit] = price.trim().split(',');
        formattedList.push({
          name: item.trim(),
          quantity: quantity.trim(),
          unit: unit ? unit.trim() : null,
          pricePerUnit: pricePerUnit ? pricePerUnit.trim() : null,
        });
      }
    }

    return formattedList;
  };

  const handleBackToRecipeBookClick = () => {
    navigate('/recipe-book');
  };

  const handleCheckboxChange = async (item) => {
    if (checkedIngredients.includes(item)) {
      setCheckedIngredients(checkedIngredients.filter((ingredient) => ingredient !== item));
      const updatedBestMatches = { ...bestMatches };
      delete updatedBestMatches[item];
      setBestMatches(updatedBestMatches);
      const updatedCounts = { ...ingredientCounts };
      delete updatedCounts[item];
      setIngredientCounts(updatedCounts);
    } else {
      const closestMatch = await searchClosestMatch(item);
      setCheckedIngredients([...checkedIngredients, item]);
      setBestMatches({ ...bestMatches, [item]: closestMatch });
      const updatedCounts = { ...ingredientCounts };
      updatedCounts[item] = 1;
      setIngredientCounts(updatedCounts);
    }
  };

  const incrementIngredientCount = (item) => {
    const updatedCounts = { ...ingredientCounts };
    updatedCounts[item] = (updatedCounts[item] || 0) + 1;
    setIngredientCounts(updatedCounts);
  };

  const decrementIngredientCount = (item) => {
    const updatedCounts = { ...ingredientCounts };
    if (updatedCounts[item] > 1) {
      updatedCounts[item] -= 1;
    } else {
      delete updatedCounts[item];
    }
    setIngredientCounts(updatedCounts);
  };

  const deleteIngredient = (item) => {
    setCheckedIngredients(checkedIngredients.filter((ingredient) => ingredient !== item));
    const updatedBestMatches = { ...bestMatches };
    delete updatedBestMatches[item];
    setBestMatches(updatedBestMatches);
    const updatedCounts = { ...ingredientCounts };
    delete updatedCounts[item];
    setIngredientCounts(updatedCounts);
  };

  const searchClosestMatch = async (ingredientName) => {
    try {
      const ingredientsCollection = collection(db, 'uk-grocery-prices');

      // Get all documents from the collection
      const querySnapshot = await getDocs(ingredientsCollection);

      // Calculate the similarity score for each document
      const similarities = querySnapshot.docs.map((doc) => {
        const productData = doc.data();
        const productName = productData.productName ? productData.productName.toLowerCase() : '';
        const distance = levenshteinDistance(ingredientName.toLowerCase(), productName);
        return { doc: productData, distance };
      });

      // Sort the documents based on the similarity score (Levenshtein distance)
      similarities.sort((a, b) => a.distance - b.distance);

      // Return the best match (document with the lowest distance)
      if (similarities.length > 0) {
        return similarities[0].doc;
      } else {
        console.log('No matching documents found for', ingredientName);
        return null;
      }
    } catch (error) {
      console.error('Error searching for closest match:', error);
      return null;
    }
  };

  // Levenshtein distance implementation
  // Source: https://en.wikibooks.org/wiki/Algorithm_Implementation/Strings/Levenshtein_distance#JavaScript
  function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    let matrix = [];

    // Initialize matrix with zero distance
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (let i = 0; i <= a.length; i++) {
      matrix[0][i] = i;
    }

    // Compute Levenshtein distance
    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        if (a.charAt(i - 1) === b.charAt(j - 1)) {
          matrix[j][i] = matrix[j - 1][i - 1];
        } else {
          matrix[j][i] = Math.min(
            matrix[j - 1][i - 1] + 1, // substitution
            matrix[j][i - 1] + 1, // insertion
            matrix[j - 1][i] + 1 // deletion
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }

  const handleGenerateTotalClick = () => {
    const checkedIngredientsPrices = checkedIngredients.map((ingredient) => {
      const closestMatch = bestMatches[ingredient] || {
        productName: ingredient,
        brand: 'Brand X',
        price: '£9.99',
        pricePerMass: '£1.99/kg',
      };
      return {
        productName: closestMatch.productName,
        brand: closestMatch.brand,
        price: closestMatch.price,
        pricePerMass: closestMatch.pricePerMass,
      };
    });

    const totalCost = checkedIngredientsPrices.reduce((sum, item) => sum + parseFloat(item.price.replace('£', '')), 0);

    navigate(`/grocery-list/${id}/total-price`, {
      state: {
        checkedIngredientsPrices,
        totalCost,
        wastePercentage: 20, // Replace with actual waste percentage calculation
        recipe,
      },
    });
  };

  return (
    <div className="grocery-list-container">
      <div className="box">
        <div className="grocery-list-header">
          Grocery List for {recipe.recommendation.split('\n')[0].replace('###', '').trim()}
          <p>
            <button className="delete" onClick={handleBackToRecipeBookClick}>
              <span className="icon is-small"></span>
              <span>X</span>
            </button>
          </p>
        </div>
        <div className="recipe-image-container">
          <img src={`data:image/png;base64,${recipe.imageData}`} alt="Recipe" className="recipe-image" />
        </div>
        <div className="grocery-list">
          <table className="table is-striped is-fullwidth is-mobile">
            <thead>
              <tr>
                <th>Ingredient</th>
                <th>Quantity</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {groceryList.map((item, index) => {
                const isIngredient = !item.name.includes('###') && !item.name.includes('Optional');
                if (isIngredient) {
                  const count = ingredientCounts[item.name] || 1;
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{count}</td>
                      <td>
                        <button className="button is-medium is-success" onClick={() => incrementIngredientCount(item.name)}>
                          <span className="icon">
                            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                          </span>
                        </button>
                        <button className="button is-medium is-danger" onClick={() => decrementIngredientCount(item.name)}>
                          <span className="icon">
                            <FontAwesomeIcon icon={faMinusCircle} size="2x" />
                          </span>
                        </button>
                        <button className="button is-medium is-danger" onClick={() => deleteIngredient(item.name)}>
                          <span className="icon">
                            <FontAwesomeIcon icon={faTrashAlt} size="2x" />
                          </span>
                        </button>
                      </td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
        <div className="generate-list-btn-container">
          <button
            className={`button is-success generate-list-btn ${isGeneratingList ? 'is-loading' : ''}`}
            onClick={handleGenerateListClick}
            disabled={isGeneratingList}
          >
            <span className="icon is-small">
              {isGeneratingList ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faList} />}
            </span>
            <span>{isGeneratingList ? 'Generating List...' : 'Generate List'}</span>
          </button>
          {showTotalButton && (
            <button className="button is-primary generate-total-btn" onClick={handleGenerateTotalClick}>
              <span className="icon">
                <FontAwesomeIcon icon={faDollarSign} size="lg" />
              </span>
              <span>Generate Total</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipeGroceryListPage;