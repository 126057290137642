import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import './Leaderboard.css';

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      const db = getFirestore();
      const leaderboardCollection = collection(db, 'leaderboard');
      const leaderboardSnapshot = await getDocs(leaderboardCollection);
      const leaderboardDataArray = leaderboardSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeaderboardData(leaderboardDataArray);
    };
    fetchLeaderboardData();
  }, []);

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faMedal} /> Leaderboard
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.map((entry, index) => (
                <tr key={entry.id}>
                  <td>{index + 1}</td>
                  <td>{entry.name}</td>
                  <td>{entry.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </article>
    </div>
  );
};

export default Leaderboard;