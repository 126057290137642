import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GroupGroceryShopping.css';

const GroupGroceryShopping = () => {
  const [groceryItem, setGroceryItem] = useState('');
  const [groceryList, setGroceryList] = useState([]);
  const [assignee, setAssignee] = useState('');
  const [teamMembers, setTeamMembers] = useState(['Alice', 'Bob', 'Charlie']);
  const navigate = useNavigate();

  const handleAddItem = (e) => {
    e.preventDefault();
    if (!groceryItem) return;

    const newItem = {
      name: groceryItem,
      assignee,
      purchased: false,
    };
    setGroceryList([...groceryList, newItem]);
    setGroceryItem('');
    setAssignee('');
  };

  const handleItemPurchased = (index) => {
    const newList = [...groceryList];
    newList[index].purchased = !newList[index].purchased;
    setGroceryList(newList);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <article className="message" style={{ color: '#698B01' }}>
        <div className="message-header">
          <p>Group Grocery Shopping</p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
        <div className="message-body">
          <form onSubmit={handleAddItem}>
            <div className="field">
              <label className="label">Add Grocery Item</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Item Name"
                  value={groceryItem}
                  onChange={(e) => setGroceryItem(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Assign To</label>
              <div className="control">
                <div className="select">
                  <select value={assignee} onChange={(e) => setAssignee(e.target.value)}>
                    <option value="">Select Team Member</option>
                    {teamMembers.map((member, index) => (
                      <option key={index} value={member}>
                        {member}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  style={{ backgroundColor: '#698B01' }}
                >
                  Add to List
                </button>
              </div>
            </div>
          </form>
          <div className="list is-hoverable mt-5">
            {groceryList.map((item, index) => (
              <a
                key={index}
                className={`list-item ${item.purchased ? 'has-text-grey-lighter' : ''}`}
                onClick={() => handleItemPurchased(index)}
              >
                {item.name} - {item.assignee} {item.purchased ? '(Purchased)' : ''}
              </a>
            ))}
          </div>
        </div>
      </article>
    </div>
  );
};

export default GroupGroceryShopping;