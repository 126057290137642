import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './Profile.css';

const Profile = () => {
  const navigate = useNavigate();

  const userData = {
    name: 'John Doe',
    email: 'johndoe@example.com',
    bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor, magna a bibendum bibendum, augue magna tincidunt enim, eget ultricies magna augue eget est.',
    profilePicture: 'https://via.placeholder.com/150',
    // Add more fields as needed
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faUser} /> Profile
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="profile-info">
            <figure className="profile-picture">
              <img src={userData.profilePicture} alt="Profile" />
            </figure>
            <div className="profile-details">
              <h2 className="profile-name">{userData.name}</h2>
              <p className="profile-email">{userData.email}</p>
              <p className="profile-bio">{userData.bio}</p>
            </div>
          </div>
          {/* Add more profile information as needed */}
        </div>
      </article>
    </div>
  );
};

export default Profile;