import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import './Settings.css';

const Settings1 = () => {
  const [userData, setUserData] = useState({
    name: 'John Doe',
    email: 'johndoe@example.com',
    // Add more fields as needed
  });
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSaveChanges = () => {
    // Simulating saving changes
    console.log('Saving changes:', userData);
    setEditMode(false);
  };

  return (
    <div className="container">
      <article className="message">
        <div className="message-header" style={{ backgroundColor: '#698B01', color: 'white' }}>
          <p>
            <FontAwesomeIcon icon={faWrench} /> Settings
          </p>
          <button onClick={() => navigate(-1)} className="delete" aria-label="delete"></button>
        </div>
        <div className="message-body" style={{ backgroundColor: 'white' }}>
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="name"
                value={userData.name}
                onChange={handleInputChange}
                readOnly={!editMode}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Email</label>
            <div className="control">
              <input
                className="input"
                type="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                readOnly={!editMode}
              />
            </div>
          </div>
          {/* Add more fields as needed */}
          <div className="field is-grouped">
            <div className="control">
              {editMode ? (
                <button className="button" onClick={handleSaveChanges} style={{ backgroundColor: '#698B01', color: 'white' }}>
                  Save Changes
                </button>
              ) : (
                <button className="button" onClick={() => setEditMode(true)} style={{ backgroundColor: '#698B01', color: 'white' }}>
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Settings1;