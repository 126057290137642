import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TeamMealPlanning.css';

const TeamMealPlanning = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [mealType, setMealType] = useState('');
  const [mealName, setMealName] = useState('');
  const [mealPlans, setMealPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const mealTypeOptions = ["Breakfast", "Lunch", "Dinner", "Snack"];

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleMealTypeChange = (e) => {
    setMealType(e.target.value);
  };

  const handleMealNameChange = (e) => {
    setMealName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newMealPlan = {
      date: selectedDate,
      mealType,
      mealName,
    };
    setMealPlans([...mealPlans, newMealPlan]);
    setLoading(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <article className="message" style={{ color: '#698B01' }}>
        <div className="message-header">
          <p>Team Meal Planning</p>
          <button onClick={handleGoBack} className="delete"></button>
        </div>
        <div className="message-body">
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Date</label>
              <div className="control">
                <input
                  type="date"
                  className="input"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Meal Type</label>
              <div className="control">
                <div className="select">
                  <select value={mealType} onChange={handleMealTypeChange}>
                    <option value="">Select Meal Type</option>
                    {mealTypeOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Meal Name</label>
              <div className="control">
                <input
                  type="text"
                  className="input"
                  value={mealName}
                  onChange={handleMealNameChange}
                  placeholder="Enter Meal Name"
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  disabled={loading}
                  style={{ backgroundColor: '#698B01' }}
                >
                  {loading ? 'Submitting...' : 'Submit Meal Plan'}
                </button>
              </div>
            </div>
          </form>
          {mealPlans.length > 0 && (
            <div className="box mt-5">
              <h3 className="title is-5">Upcoming Meals:</h3>
              <ul>
                {mealPlans.map((plan, index) => (
                  <li key={index}>{`${plan.date}: ${plan.mealType} - ${plan.mealName}`}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </article>
    </div>
  );
};

export default TeamMealPlanning;